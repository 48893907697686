import { Flex, Grid } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { Input } from '../../components/Input'
import getFieldStatus from '../../model/helpers/StatusHelper'
import Switch from '../../components/Switch'
import Textarea from '../../components/Textarea'
import * as Yup from 'yup'
import Button from '../../components/Button'
import Save from '../../icons/Save'
import Cancel from '../../icons/Cancel'
import { useUpdateWorkspace } from '../../model/api/useUpdateWorkspace'
import { useQueryClient } from 'react-query'
import { useCustomToast } from '../../components/Toast'
import ApiRouteEnum from '../../model/enums/ApiRouteEnum'
import { WorkspaceMarketingDataFormLabels } from '../../model/constants/FormLabels'
import { RegisterStep2Schema } from '../../model/schemas/RegisterStep2Schema'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import { Box } from '../../components/Box'
import useIsMobile from '../../model/helpers/useIsMobile'
import { ToastHelper } from '../../model/helpers/ToastHelper'

const WorkspaceMarketingForm = ({
  workspaceData,
  onMarketingModalClose,
}: {
  workspaceData: any
  onMarketingModalClose: () => void
}) => {
  const query = useQueryClient()
  const { showCustomToast, onCloseHandler, setIsHovering } = useCustomToast()
  const isMobile = useIsMobile()
  const successCallback = () => {
    query.invalidateQueries(ApiRouteEnum.WORKSPACE_BY_ID)
    showCustomToast({
      status: 'success',
      title: 'Zmeny boli uložené',
      label: <div>Nastavenia zobrazenia na webe ViVeM boli úspešne upravené</div>,
    })
  }
  const errorCallback = () => {
    showCustomToast({
      status: 'error',
      title: 'Položku sa nepodarilo upraviť',
      label: <div>Pri zmene nastavení zobrazenia na webe ViVeM nastala chyba</div>,
    })
  }
  const updateWorkspace = useUpdateWorkspace(successCallback, errorCallback)

  const handleFormSubmit = (values: Yup.InferType<typeof RegisterStep2Schema>) => {
    updateWorkspace.mutate({
      workspaceData: {
        name: workspaceData.name,
        email: workspaceData.email,
        companyIdentificationNumber: workspaceData.companyIdentificationNumber,
        vatID: workspaceData.vatID,
        address: workspaceData.address,
        workspaceMarketingData: {
          url: values.url,
          title: values.title,
          description: values.description !== '' ? values.description : null,
          showOnWeb: values.showOnWeb,
          phone: values?.phone?.replace(/\s/g, '') || null,
        },
      },
      id: workspaceData.id,
    })
    onMarketingModalClose()
  }

  const initialValues = {
    title: workspaceData?.workspaceMarketingData?.title || '',
    phone: workspaceData?.workspaceMarketingData?.phone?.replace(/\s/g, '') || '',
    url: workspaceData?.workspaceMarketingData?.url || '',
    description: workspaceData?.workspaceMarketingData?.description || null,
    showOnWeb: workspaceData?.workspaceMarketingData?.showOnWeb || false,
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={RegisterStep2Schema}
      >
        {({ errors, touched, submitForm, values, setFieldTouched }) => {
          return (
            <>
              <Form>
                <Grid
                  templateColumns={{ sm: 'initial', md: 'repeat(2, 1fr)' }}
                  templateRows={{ sm: 'initial', md: 'repeat(2, 1fr)' }}
                  gridAutoFlow={{ sm: 'initial', md: 'column' }}
                  justifyItems={'center'}
                  gap={'0.8rem'}
                >
                  <Field
                    name="title"
                    label="webový názov"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'title')}
                  />
                  <Field
                    name="phone"
                    label="telefónne číslo"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'phone')}
                  />
                  <Field
                    name="url"
                    label="URL webstránky"
                    type="text"
                    component={Input}
                    status={getFieldStatus(errors, touched, 'url')}
                  />
                  <Flex
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    fontSize={'0.8rem'}
                    w={'100%'}
                    fontWeight={'semibold'}
                  >
                    zverejniť na stránke ViVeM{' '}
                    <Box
                      h={'unset'}
                      onClick={
                        isMobile &&
                        (workspaceData.address === null ||
                          workspaceData.address?.city === null ||
                          workspaceData.address?.street === null)
                          ? () => {
                              setIsHovering(true)
                              showCustomToast({
                                status: 'warning',
                                title: 'Nie je možné zverejniť na stránke ViVeM',
                                label: (
                                  <div>
                                    Vo Vašom pracovisku je nutné najprv uviesť mesto a ulicu Vašeho
                                    pracoviska.
                                  </div>
                                ),
                              })
                            }
                          : undefined
                      }
                      onMouseEnter={
                        workspaceData.address === null ||
                        workspaceData.address?.city === null ||
                        workspaceData.address?.street === null
                          ? () => {
                              setIsHovering(true)
                              showCustomToast({
                                status: 'warning',
                                title: 'Nie je možné zverejniť na stránke ViVeM',
                                label: (
                                  <div>
                                    Vo Vašom pracovisku je nutné najprv uviesť mesto a ulicu Vašeho
                                    pracoviska.
                                  </div>
                                ),
                              })
                            }
                          : undefined
                      }
                      onMouseLeave={() => onCloseHandler && onCloseHandler()}
                    >
                      <Field
                        name="showOnWeb"
                        isChecked={values.showOnWeb}
                        isDisabled={
                          workspaceData.address === null ||
                          workspaceData.address?.city === null ||
                          workspaceData.address?.street === null
                        }
                        component={Switch}
                      />
                    </Box>
                  </Flex>
                </Grid>
                <Field
                  mt={'0.8rem'}
                  name="description"
                  label={'text na webstránke'}
                  component={Textarea}
                />

                <Grid placeItems={'center'} mt={'1rem'}>
                  <Grid
                    gridTemplateColumns={'repeat(2, 1fr)'}
                    gap={'1rem'}
                    w={{ sm: '100%', md: '50%' }}
                  >
                    <Button variant={'blue'} onClick={onMarketingModalClose}>
                      <Cancel />
                    </Button>
                    <Button
                      variant={'green'}
                      onClick={() =>
                        ToastHelper({
                          submitForm,
                          errors,
                          setFieldTouched,
                          labels: WorkspaceMarketingDataFormLabels,
                          showCustomToast,
                        })
                      }
                    >
                      <Save />
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default WorkspaceMarketingForm
