import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody } from '@chakra-ui/react'
import { Box } from './Box'
import Button from './Button'

const ConfirmCancelPayment = ({
  isConfirmModalOpen,
  onConfirmModalClose,
  onClickAction,
}: {
  isConfirmModalOpen: boolean
  onConfirmModalClose: () => void
  onClickAction: () => void
}) => {
  return (
    <Modal size={'xs'} isOpen={isConfirmModalOpen} onClose={onConfirmModalClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody pb={'0'}>
          <Box textAlign={'center'} m={'2rem'} fontWeight={'semibold'}>
            Chcete naozaj zrušiť súčasný odber?
          </Box>
        </ModalBody>

        <ModalFooter display={'flex'} justifyContent={'space-between'} gap={3} pt={'0'}>
          <Button variant={'blue'} onClick={onClickAction}>
            ÁNO
          </Button>
          <Button variant={'red'} onClick={onConfirmModalClose}>
            NIE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmCancelPayment
