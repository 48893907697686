import {
  Flex,
  Grid,
  Link,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  useDisclosure,
} from '@chakra-ui/react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Box } from '../../components/Box'
import Button from '../../components/Button'
import Spinner from '../../components/Spinner'
import WorkspaceLimitCard from '../../components/WorkspaceLimitCard'
import Wallet from '../../icons/Wallet'
import useGetWorkspaceQuotas from '../../model/api/useGetWorkspaceQuotas'
import { colors } from '../../theme'
import { useEffect, useState } from 'react'
import Calendar from '../../icons/Calendar'
import Menu from '../../components/Menu'
import useIsMobile from '../../model/helpers/useIsMobile'
import Cancel from '../../icons/Cancel'
import useCreatePayment from '../../model/api/useCreatePayment'
import { format } from 'date-fns'
import { useCancelPayment } from '../../model/api/useCancelPayment'
import { useCustomToast } from '../../components/Toast'
import ConfirmCancelPayment from '../../components/ConfirmCancelPayment'

const WorkspaceLimitsSettings = () => {
  const { data } = useOutletContext() as any
  const [sliderDefaultValue, setSliderDefaultValue] = useState<number>(0)
  const [sliderValue, setSliderValue] = useState(0)
  const [priceValue, setPriceValue] = useState(0)
  const [subscription, setSubscription] = useState({
    amount: 0,
    tier: {
      maxUsers: 0,
      maxVehicles: 0,
      diskSize: 0,
      maximumEmailsPerMonth: 0,
      maxApiCallsPerMonth: 0,
    },
  })
  const [gatewayUrl, setGatewayUrl] = useState('')
  const { mutate: createPayment } = useCreatePayment()
  const cancelPayment = useCancelPayment()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { showCustomToast } = useCustomToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSetSubscription = (values: {
    amount: number
    tier: {
      maxUsers: number
      maxVehicles: number
      diskSize: number
      maximumEmailsPerMonth: number
      maxApiCallsPerMonth: number
    }
  }) => {
    setSubscription(values)
  }

  const computeSliderValues = (value: number, backwards?: boolean) => {
    if (backwards) {
      if (value === 0) {
        setPriceValue(0)
        setSliderValue(0)
        setSliderDefaultValue(0)
      } else if (value === 10) {
        setPriceValue(1)
        setSliderValue(10)
        setSliderDefaultValue(25)
      } else if (value === 25) {
        setPriceValue(2)
        setSliderValue(25)
        setSliderDefaultValue(50)
      } else if (value === 50) {
        setPriceValue(4)
        setSliderValue(50)
        setSliderDefaultValue(75)
      } else {
        setPriceValue(6)
        setSliderValue(100)
        setSliderDefaultValue(100)
      }
    } else {
      if (value === 0) {
        setPriceValue(0)
        setSliderValue(0)
      } else if (value === 25) {
        setPriceValue(1)
        setSliderValue(10)
      } else if (value === 50) {
        setPriceValue(2)
        setSliderValue(25)
      } else if (value === 75) {
        setPriceValue(4)
        setSliderValue(50)
      } else {
        setPriceValue(6)
        setSliderValue(100)
      }
    }
  }

  const computeVehicleNumber = (emails: number, vehicles: number): number => {
    if (emails === 100) {
      return vehicles - 5
    } else if (emails === 200) {
      return vehicles - 10
    } else if (emails === 1000) {
      return vehicles - 20
    }
    return vehicles - 50
  }

  useEffect(() => {
    let trustPayScript: any = null
    const clearSetTimeout = setTimeout(() => {
      trustPayScript = document.createElement('script')
      trustPayScript.src = '/build/trustPayScript.js'
      trustPayScript.async = true
      document.body.appendChild(trustPayScript)
    }, 500)

    return () => {
      clearTimeout(clearSetTimeout)
      trustPayScript !== null && document.body.removeChild(trustPayScript)
    }
  }, [])

  const { data: workspaceDataQuotas } = useGetWorkspaceQuotas(data.workspace.id)

  useEffect(() => {
    if (workspaceDataQuotas) {
      const vehicleNumber = computeVehicleNumber(
        workspaceDataQuotas.emails.maximum,
        workspaceDataQuotas.vehicle.maximum,
      )
      computeSliderValues(vehicleNumber, true)
      setSubscription({
        amount: subscription.amount,
        tier: {
          maxUsers: workspaceDataQuotas.users.maximum,
          maxVehicles: workspaceDataQuotas.vehicle.maximum - vehicleNumber,
          diskSize: workspaceDataQuotas.diskSize.maximum,
          maximumEmailsPerMonth: workspaceDataQuotas.emails.maximum,
          maxApiCallsPerMonth: workspaceDataQuotas.apiCalls.maximum,
        },
      })
    }
  }, [workspaceDataQuotas])

  if (!workspaceDataQuotas) {
    return <Spinner />
  }
  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  }
  return (
    <Flex
      w={'100%'}
      h={'100%'}
      alignItems={'center'}
      flexFlow={'column'}
      overflow={'auto'}
      padding={'1%'}
    >
      <Box w={'75%'} mt={'1rem'} fontSize={'0.75rem'}>
        <Grid
          gridTemplateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gap={'1rem'}
          justifyContent={'end'}
          alignItems={'center'}
          mb={'1rem'}
        >
          <Box />
          <Box fontSize={'1.8rem'} fontWeight={'semibold'} w={'100%'} textAlign={'center'}>
            Zmeniť balíček
          </Box>
          <Flex
            alignItems={'center'}
            fontWeight={'semibold'}
            w={'100%'}
            bg={colors.gray[700]}
            color={colors.blue[100]}
            fontSize={'0.8rem'}
            p={'0.5rem'}
          >
            <span>
              Pokiaľ máte špecialne požiadavky na limity, neváhajte nás kontakovať na:
              <Link href="mailto:info@vivem.sk" color={colors.blue[500]}>
                {' '}
                info@vivem.sk
              </Link>
            </span>
          </Flex>
        </Grid>
        <Grid
          gridTemplateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gridTemplateRows={'repeat(1,1fr)'}
          gap={'1rem'}
          marginBottom={{ sm: '10rem', md: 'initial' }}
        >
          <Grid gridTemplateRows={'repeat(2, 1fr)'} gap={'1rem'}>
            <WorkspaceLimitCard
              subscription={subscription}
              onSetSubscription={handleSetSubscription}
              packageInfo={{
                tier: 'Free',
                price: 0,
                vehicles: 5,
                users: 5,
                emails: 100,
                data: <>100 MB (min 50 foto)</>,
                dataNumber: 100000,
                apiCalls: 20,
              }}
            />
            <WorkspaceLimitCard
              subscription={subscription}
              onSetSubscription={handleSetSubscription}
              packageInfo={{
                tier: 'Starter',
                price: 5,
                vehicles: 10,
                users: 5,
                emails: 200,
                data: <>250 MB (min 125 foto)</>,
                dataNumber: 250000,
                apiCalls: 30,
              }}
            />
          </Grid>
          <Grid gridTemplateRows={'repeat(2, 1fr)'} gap={'1rem'}>
            <WorkspaceLimitCard
              subscription={subscription}
              onSetSubscription={handleSetSubscription}
              packageInfo={{
                tier: 'Standard',
                price: 10,
                vehicles: 20,
                users: 10,
                emails: 1000,
                data: <>500 MB (min 250 foto)</>,
                dataNumber: 500000,
                apiCalls: 50,
              }}
            />
            <WorkspaceLimitCard
              subscription={subscription}
              onSetSubscription={handleSetSubscription}
              packageInfo={{
                tier: 'Premium',
                price: 20,
                vehicles: 50,
                users: 20,
                emails: 5000,
                data: <>1 GB (min 500 foto)</>,
                dataNumber: 1000000,
                apiCalls: 100,
              }}
            />
          </Grid>
          <Flex gap={'1rem'} flexDirection={'column'}>
            <Flex
              w={'100%'}
              bg={colors.blue[100]}
              flex={1}
              p={'1rem'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={'1rem'}
            >
              <Flex
                fontWeight={'semibold'}
                fontSize={'1.2rem'}
                alignItems={'center'}
                w={'100%'}
                justifyContent={'space-between'}
              >
                <Box>Pridať vozidlá</Box>
                <Box fontSize={'1.8rem'} fontWeight={'bold'}>
                  {priceValue}€
                </Box>
              </Flex>
              <Box w={'90%'}>
                {sliderDefaultValue !== undefined && (
                  <Slider
                    step={25}
                    defaultValue={sliderDefaultValue}
                    aria-label="slider-ex-6"
                    onChange={(val) => {
                      computeSliderValues(val)
                    }}
                    m={'0 auto'}
                  >
                    <SliderMark value={0} {...labelStyles}>
                      0
                    </SliderMark>
                    <SliderMark value={25} {...labelStyles}>
                      10
                    </SliderMark>
                    <SliderMark value={50} {...labelStyles}>
                      25
                    </SliderMark>
                    <SliderMark value={75} {...labelStyles}>
                      50
                    </SliderMark>
                    <SliderMark value={100} {...labelStyles}>
                      100
                    </SliderMark>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                )}
              </Box>
              <Box w={'100%'} m={'auto 0'}>
                <Box fontSize={'1.2rem'} m={'1rem 0'} fontWeight={'semibold'}>
                  Vaše limity:
                </Box>
                <Grid
                  w={'100%'}
                  m={'auto 0'}
                  gridTemplateColumns={'repeat(2, 1fr)'}
                  gridTemplateRows={'repeat(5, 1fr)'}
                  gap={'1rem'}
                >
                  <Box fontWeight={'semibold'}>Počet vozidiel:</Box>
                  <Box fontSize={'12px'}>{sliderValue + subscription.tier.maxVehicles}</Box>
                  <Box fontWeight={'semibold'}>Počet užívateľov:</Box>
                  <Box fontSize={'12px'}>{subscription.tier.maxUsers}</Box>
                  <Box fontWeight={'semibold'} fontSize={'12px'}>
                    Počet e-mailov:
                  </Box>
                  <Box fontSize={'12px'}>{subscription.tier.maximumEmailsPerMonth}</Box>
                  <Box fontWeight={'semibold'}>Množstvo dát:</Box>
                  <Box fontSize={'12px'}>
                    {subscription.tier.diskSize === 1000000 ? 1 : subscription.tier.diskSize / 1000}{' '}
                    {subscription.tier.diskSize === 1000000 ? 'GB' : 'MB'} (min{' '}
                    {subscription.tier.diskSize / 2000} foto)
                  </Box>
                  <Box fontWeight={'semibold'}>API volania:</Box>
                  <Box fontSize={'12px'}>
                    {subscription.tier.maxApiCallsPerMonth} (Načítanie VIN)
                  </Box>
                </Grid>
              </Box>
              <Grid
                gridTemplateColumns={'repeat(2, 1fr)'}
                gridTemplateRows={'repeat(2, 1fr)'}
                autoFlow={'column'}
                w={'100%'}
                mb={'2rem'}
              >
                <Box mt={'auto'}>K úhrade:</Box>
                <Flex fontWeight={'semibold'} fontSize={'2rem'} alignItems={'flex-start'}>
                  <Box>{`${priceValue + subscription.amount}€`}</Box>
                </Flex>
                <Box mt={'auto'}>Ďaľšia platba:</Box>
                <Flex alignItems={'flex-start'} mt={'0.6rem'}>
                  <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                  <Box ml={'0.5rem'} fontWeight={'semibold'}>
                    {workspaceDataQuotas.nextPaymentDate
                      ? format(new Date(workspaceDataQuotas.nextPaymentDate), 'dd.MM.yyyy')
                      : ''}
                  </Box>
                </Flex>
              </Grid>
            </Flex>
            <Button variant={'red'} size="medium" w={'100%'} onClick={onOpen}>
              <Cancel />
              <Box w={'100%'} whiteSpace={'pre-wrap'}>
                Zrušiť odber
              </Box>
            </Button>
            <Button
              variant={'green'}
              size="medium"
              w={'100%'}
              onClick={() =>
                createPayment(
                  {
                    paymentData: {
                      amount: priceValue + subscription.amount,
                      workspaceId: data.workspace.id,
                      tier: {
                        ...subscription.tier,
                        maxVehicles: sliderValue + subscription.tier.maxVehicles,
                      },
                    },
                  },
                  {
                    onSuccess: (data) => {
                      setGatewayUrl(data.data.gatewayUrl)
                      const popup = document.getElementsByClassName(
                        'show-popup',
                      )[0] as HTMLButtonElement
                      popup.click()
                    },
                  },
                )
              }
            >
              <Wallet />
              <Box w={'100%'} whiteSpace={'pre-wrap'}>
                SUBSCRIBE
              </Box>
            </Button>
          </Flex>
        </Grid>
      </Box>
      {isMobile && <Menu back={() => navigate(-1)} type={'basic'} />}
      <iframe className="show-popup" id="TrustPayFrame" src={gatewayUrl}></iframe>

      <ConfirmCancelPayment
        isConfirmModalOpen={isOpen}
        onConfirmModalClose={onClose}
        onClickAction={() => {
          cancelPayment.mutate(data.workspace.id, {
            onSuccess: () => {
              showCustomToast({
                status: 'success',
                title: 'Platba stornovaná',
                label: <div>Odber bol úspešne zrušený</div>,
              })
            },
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Platbu sa nepodarilo stornovať',
                label: <div>Odber sa nepodarilo zrušiť</div>,
              })
            },
          })
          onClose()
        }}
      />
    </Flex>
  )
}

export default WorkspaceLimitsSettings
