import { Grid } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { Input } from '../../components/Input'
import * as Yup from 'yup'
import { IS_REQUIRED } from '../../model/constants/ValidationMessages'
import getFieldStatus from '../../model/helpers/StatusHelper'
import Button from '../../components/Button'
import Cancel from '../../icons/Cancel'
import Save from '../../icons/Save'
import { useUpdateWorkspace } from '../../model/api/useUpdateWorkspace'
import { WorkspaceDataFormLabels } from '../../model/constants/FormLabels'
import { useCustomToast } from '../../components/Toast'
import ApiRouteEnum from '../../model/enums/ApiRouteEnum'
import { useQueryClient } from 'react-query'
import useIsMobile from '../../model/helpers/useIsMobile'
import { Box } from '../../components/Box'
import { RegisterStep1Schema } from '../../model/schemas/RegisterStep1Schema'
import { colors } from '../../theme'
import { ToastHelper } from '../../model/helpers/ToastHelper'

const WorkspaceDataForm = ({
  workspaceData,
  onWorkspaceDataModalClose,
}: {
  workspaceData: any
  onWorkspaceDataModalClose: () => void
}) => {
  const query = useQueryClient()
  const isMobile = useIsMobile()
  const { showCustomToast } = useCustomToast()
  const successCallback = () => {
    query.invalidateQueries(ApiRouteEnum.WORKSPACE_BY_ID)
    showCustomToast({
      status: 'success',
      title: 'Zmeny boli uložené',
      label: <div>Nastavenia údajov o pracovisku boli úspešne upravené</div>,
    })
  }
  const errorCallback = () => {
    showCustomToast({
      status: 'error',
      title: 'Položku sa nepodarilo upraviť',
      label: <div>Pri zmene nastavení údajov o pracovisku nastala chyba</div>,
    })
  }
  const updateWorkspace = useUpdateWorkspace(successCallback, errorCallback)
  const handleFormSubmit = (values: Yup.InferType<typeof RegisterStep1Schema>) => {
    updateWorkspace.mutate({
      workspaceData: {
        name: values.name,
        email: values.email,
        companyIdentificationNumber:
          values.companyIdentificationNumber !== '' ? values.companyIdentificationNumber : null,
        vatId: values.vatId !== '' ? values.vatId : null,
        workspaceMarketingData: workspaceData.workspaceMarketingData
          ? {
              ...workspaceData.workspaceMarketingData,
              phone: workspaceData.workspaceMarketingData.phone ? workspaceData.workspaceMarketingData.phone.replace(/\s/g, '') : '',
            }
          : null,
        address:
          !values.city && !values.street && !values.houseNumber && !values.zipCode
            ? null
            : {
                city: values.city !== '' ? values.city : null,
                street: values.street !== '' ? values.street : null,
                houseNumber: values.houseNumber !== '' ? values.houseNumber : null,
                zipCode: values.zipCode !== '' ? values.zipCode : null,
                state: workspaceData.address?.state || 'slovensko',
              },
      },
      id: workspaceData.id,
    })
    onWorkspaceDataModalClose()
  }

  const initialValues = {
    name: workspaceData.name || '',
    email: workspaceData.email || '',
    companyIdentificationNumber: workspaceData.companyIdentificationNumber || '',
    vatId: workspaceData.vatId || null,
    city: workspaceData.address?.city || null,
    street: workspaceData.address?.street || null,
    houseNumber: workspaceData.address?.houseNumber || null,
    zipCode: workspaceData.address?.zipCode || null,
    state: workspaceData.address?.state || 'slovensko',
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={RegisterStep1Schema}
      reValidateMode={'onChange'}
    >
      {({ errors, touched, values, submitForm, setFieldTouched }) => {
        return (
          <>
            {isMobile && (
              <Grid
                fontSize={'1.2rem'}
                fontWeight={'semibold'}
                w={'100%'}
                h={'4rem'}
                placeItems={'center'}
                bg={colors.gray[700]}
                color={colors.blue[100]}
                zIndex={100}
                position={'sticky'}
                top={'0px'}
              >
                zmeniť workspace údaje
              </Grid>
            )}
            <Form>
              <Grid
                w={{ sm: '90%', md: 'initial' }}
                gridTemplateColumns={{ sm: 'initial', md: 'repeat(2, 1fr)' }}
                gridTemplateRows={{ sm: 'initial', md: 'repeat(4, 1fr)' }}
                gridAutoFlow={{ sm: 'initial', md: 'column' }}
                justifyItems={'center'}
                gap={'0.8rem'}
                m={{ sm: '14rem auto 0 auto', md: 'initial' }}
                h={{ sm: '30%', md: 'initial' }}
                overflow={'auto'}
              >
                <Field
                  name="name"
                  label="názov servisu:"
                  type="text"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'name')}
                />
                <Field
                  name="email"
                  label="e-mail:"
                  type="email"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'email')}
                />
                {isMobile && (
                  <Box fontSize={'0.8rem'} textAlign={'left'}>
                    Tento email bude použitý, pokiaľ zákazník odpíše na systémové emaily
                  </Box>
                )}
                <Field
                  name="companyIdentificationNumber"
                  label="IČO:"
                  type="text"
                  component={Input}
                  status={getFieldStatus(
                    errors,
                    touched,
                    'companyIdentificationNumber',
                    true,
                    values,
                  )}
                />
                <Field
                  name="vatId"
                  label="DIČ:"
                  type="text"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'vatId', true, values)}
                />
                <Field
                  name="city"
                  label="mesto:"
                  type="text"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'city', true, values)}
                />
                <Field
                  name="street"
                  label="ulica:"
                  type="text"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'street', true, values)}
                />
                <Field
                  name="houseNumber"
                  label="popisné číslo:"
                  type="text"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'houseNumber', true, values)}
                />
                <Field
                  name="zipCode"
                  label="PSČ:"
                  type="text"
                  component={Input}
                  status={getFieldStatus(errors, touched, 'zipCode', true, values)}
                />
              </Grid>
              <Grid placeItems={'center'} mt={'1rem'} mb={{ sm: '10rem', md: 'initial' }}>
                <Grid
                  gridTemplateColumns={'repeat(2, 1fr)'}
                  gap={'1rem'}
                  w={{ sm: '90%', md: '50%' }}
                  m={{ sm: '0 auto 6rem auto', md: '0 auto' }}
                >
                  <Button variant={'blue'} onClick={onWorkspaceDataModalClose}>
                    <Cancel />
                  </Button>
                  <Button
                    variant={'green'}
                    onClick={() =>
                      ToastHelper({
                        submitForm,
                        errors,
                        setFieldTouched,
                        labels: WorkspaceDataForm,
                        showCustomToast,
                      })
                    }
                  >
                    <Save />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default WorkspaceDataForm
