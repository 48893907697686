import ShareIcon from '../icons/ShareIcon'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Box,
  Grid,
  Image,
} from '@chakra-ui/react'
import Close from '../icons/Close'
import { colors } from '../theme'
import { DisabledInput } from './DisabledInput'
import Button from './Button'
import QRIcon from '../icons/QRIcon'
import { Field, Form, Formik } from 'formik'
import Switch from './Switch'
import Save from '../icons/Save'
import { useRequestCode } from '../model/api/useRequestCode'
import useUpdateShareSettings from '../model/api/useUpdateShareSettings'
import useGetCode from '../model/api/useGetCar copy'
import { useQueryClient } from 'react-query'
import { useCustomToast } from './Toast'
import ApiRouteEnum from '../model/enums/ApiRouteEnum'
import Spinner from './Spinner'
import { useEffect, useState } from 'react'

const ShareModal = ({
  isShareModalOpen,
  onShareModalClose,
  vehicleServiceId,
  priceSharing,
}: {
  isShareModalOpen: boolean
  onShareModalClose: () => void
  vehicleServiceId: string
  priceSharing: { totalPrice: boolean; priceItems: boolean }
}) => {
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const { data: codePrefillData } = useGetCode(vehicleServiceId)
  const { mutate: mutateGetCode, data: codeData } = useRequestCode()
  const [finalImageUrl, setFinalImageUrl] = useState('')
  const mutateShareSettings = useUpdateShareSettings()
  const handleFormSubmit = (values: any) => {
    mutateShareSettings.mutate(
      { id: vehicleServiceId, updateShareSettings: values },
      {
        onSuccess: () => {
          query.invalidateQueries(ApiRouteEnum.VEHICLE_SERVIS)
          showCustomToast({
            status: 'success',
            title: 'Zmeny boli uložené',
            label: <div>Nastavenia zdieľania boli zmenené.</div>,
          })
        },
        onError: () => {
          showCustomToast({
            status: 'error',
            title: 'Zmeny sa nepodarilo uložiť',
            label: <div>Nastavenia zdieľania sa nepodarilo zmeniť.</div>,
          })
        },
      },
    )
    onShareModalClose()
  }
  useEffect(() => {
    if (codeData?.data?.url || codePrefillData?.url) {
      setFinalImageUrl(
        `https://quickchart.io/qr?text=${codeData?.data?.url || codePrefillData?.url}`,
      )
    }
  }, [codeData, codePrefillData])
  return (
    <Modal size={'xl'} isOpen={isShareModalOpen} onClose={onShareModalClose} isCentered>
      <ModalOverlay />
      <ModalContent display={'flex'} justifyContent={'center'}>
        <ModalHeader display={'flex'} alignItems={'center'} p={0}>
          <ShareIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} /> Nastavenie
          zdieľania{' '}
          <Close
            fontSize={'1.6rem'}
            bg={colors.red[500]}
            ml={'auto'}
            cursor={'pointer'}
            onClick={() => onShareModalClose()}
            p={4}
          />
        </ModalHeader>

        <ModalBody p={'1rem'} display={'flex'} flexFlow={'column'} gap={'1rem'} pb={'1rem'}>
          <Flex flexFlow={'column'} alignItems={'center'}>
            <Grid w={'50%'} gap={'1rem'}>
              <DisabledInput
                defaultValue={
                  codeData?.data.link || codePrefillData?.link
                    ? codeData?.data.link || codePrefillData.link
                    : '000 000'
                }
                label={'prístupový kód'}
              />
              <Box p={'1rem'} w={'100%'}>
                {codeData?.data?.url || codePrefillData?.url ? (
                  <Image
                    margin={'auto'}
                    src={finalImageUrl}
                    aria-label="QR code"
                    fallback={<Spinner />}
                  />
                ) : (
                  <QRIcon color={'#71819B'} fontSize={'5rem'} />
                )}
              </Box>
              <Button variant={'blue'} onClick={() => mutateGetCode(vehicleServiceId)}>
                generovať kód
              </Button>
              <Formik
                initialValues={{
                  totalPrice: priceSharing.totalPrice,
                  priceItems: priceSharing.priceItems,
                }}
                onSubmit={handleFormSubmit}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      <Flex
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        fontSize={'0.8rem'}
                        fontWeight={'semibold'}
                      >
                        Zdieľať cenu{' '}
                        <Field
                          name="totalPrice"
                          isChecked={values.totalPrice}
                          component={Switch}
                          onChange={(e: any) => {
                            const value = values.totalPrice
                            setFieldValue('totalPrice', !value)
                            if (!value === false) {
                              setFieldValue('priceItems', false)
                            }
                          }}
                        />
                      </Flex>
                      <Flex
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        fontSize={'0.8rem'}
                        fontWeight={'semibold'}
                        mt={'1rem'}
                      >
                        Zdieľať položky ceny{' '}
                        <Field
                          name="priceItems"
                          isChecked={values.priceItems}
                          isDisabled={!values.totalPrice}
                          component={Switch}
                        />
                      </Flex>
                      <Button variant={'green'} type={'submit'} mt={'1rem'}>
                        <Save />
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </Grid>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ShareModal
