import { Flex, useDisclosure } from '@chakra-ui/react'
import { colors } from '../../theme'
import { Box } from '../../components/Box'
import FilterTag from '../../components/FilterTag'
import VehicleCard from '../../components/VehicleCard'
import CarIcon from '../../icons/CarIcon'
import { Add } from '../../icons/Add'
import Button from '../../components/Button'
import useGetAllCars from '../../model/api/useGetAllCars'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import RouterEnum from '../../model/enums/RouterEnum'
import useGetWorkspaceQuotas from '../../model/api/useGetWorkspaceQuotas'
import FilterModal from '../../components/FilterModal'
import Spinner from '../../components/Spinner'
import { useCustomToast } from '../../components/Toast'
import useGetAllVehicleOwners from '../../model/api/useGetAllVehicleOwners'

const VehiclesOverview = () => {
  const {data: outletData, totalBlock} = useOutletContext() as any
  const { data: workspaceDataQuotas, isLoading: workspaceLoading } = useGetWorkspaceQuotas(
    outletData.workspace.id,
  )
  const { data: vehicleOwnersData, isLoading: isVehicleOwnersLoading } = useGetAllVehicleOwners()
  const { showCustomToast, onCloseHandler, setIsHovering } = useCustomToast()
  const [filters, setFilters] = useState<{
    [key: string]: any
  }>({})
  const [data, setData] = useState<any>()
  const { data: vehiclesData, getAllCars, isLoading } = useGetAllCars()
  const navigate = useNavigate()

  useEffect(() => {
    setData(vehiclesData)
  }, [vehiclesData])

  useEffect(() => {
    getAllCars({
      ...filters,
      ...(filters.ownerId && { ownerId: filters.ownerId.value }),
    }).then((res) => {
      setData(res)
    })
  }, [filters])

  const {
    isOpen: isAddFilterOpen,
    onOpen: onAddFilterOpen,
    onClose: onAddFilterClose,
  } = useDisclosure()

  const handleFilterRemove = (key: string) => {
    const newFilters = { ...filters }
    delete newFilters[key]
    setFilters(newFilters)
  }

  const handleResetFilters = () => {
    setFilters({})
  }

  const filterLabels = {
    ownerId: 'Majiteľ',
    model: 'Model',
    licensePlate: 'ŠPZ',
    ownerEmail: 'Email majiteľa',
    brand: 'Značka',
    vinCode: 'VIN',
  }

  const mappedData = useMemo(() => {
    if (!data) {
      return null
    }
    return (
      Array.isArray(data) &&
      data.map((item: any) => {
        return <VehicleCard item={item} key={item.id} />
      })
    )
  }, [data])

  if (isLoading || !workspaceDataQuotas || isVehicleOwnersLoading) {
    return <Spinner />
  }

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      alignItems={'center'}
      flexFlow={'column'}
      overflow={'auto'}
      padding={'1%'}
    >
      <Box w={'75%'}>
        <Box
          fontSize={'1.8rem'}
          fontWeight={'semibold'}
          w={'100%'}
          textAlign={'center'}
          m={'1rem 0'}
        >
          Prehľad vozidiel v evidencii
        </Box>
        <Flex fontSize={'1rem'} fontWeight={'semibold'} alignItems={'center'}>
          Aktívne filtre:
          <Flex flexWrap={'wrap'} gap={2}>
            {Object.keys(filters).map((key) => {
              if (typeof filters[key] === 'object') {
                return (
                  <FilterTag
                    title={`${filterLabels[key as keyof typeof filterLabels]}: ${
                      filters[key].label
                    }`}
                    onClick={() => handleFilterRemove(key)}
                  />
                )
              } else {
                return (
                  <FilterTag
                    title={`${filterLabels[key as keyof typeof filterLabels]}: ${filters[key]}`}
                    onClick={() => handleFilterRemove(key)}
                  />
                )
              }
            })}
            <FilterTag type={'add'} title={'pridať filter'} onClick={onAddFilterOpen} />
          </Flex>
        </Flex>
        <Flex mt={'2rem'} flexFlow={'row wrap'} gap={'1rem'}>
          <Box
            w={'30%'}
            h={'unset'}
            onMouseEnter={
              workspaceDataQuotas.vehicle.current >= workspaceDataQuotas.vehicle.maximum
                ? () => {
                    setIsHovering(true)
                    showCustomToast({
                      status: 'warning',
                      title: 'Dosiahli ste maximálny počet vozidiel',
                      label: (
                        <div>
                          Vo Vašom pracovisku bol dosiahnutý maximálny možný počet vozidiel. Ak
                          chcete pridať ďalšie vozidlo, odstráňte nejaké z existujúcich alebo
                          navýšte limit v nastaveniach pracoviska.
                        </div>
                      ),
                    })
                  }
                : vehicleOwnersData?.length === 0
                ? () => {
                    setIsHovering(true)
                    showCustomToast({
                      status: 'warning',
                      title: 'Nie je možné pridať vozidlo',
                      label: (
                        <div>
                          Vo Vašom pracovisku je nutné najprv založiť aspoň jedného zákazníka.
                        </div>
                      ),
                    })
                  }
                : undefined
            }
            onMouseLeave={() => onCloseHandler && onCloseHandler()}
          >
            <Button
              size="large"
              variant="basic"
              w={'100%'}
              h={'100%'}
              bg={colors.blue[100]}
              onClick={
                () =>
                navigate(RouterEnum.VEHICLE_FORM_CREATE, { state: { mode: 'create' } })
              }
              isDisabled={
                workspaceDataQuotas.vehicle.current >= workspaceDataQuotas.vehicle.maximum ||
                vehicleOwnersData?.length === 0 || totalBlock
              }
            >
              <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
                <Add fontSize={'2rem'} />
                <CarIcon fontSize={'2rem'} />
              </Flex>
              pridať vozidlo
            </Button>
          </Box>
          {mappedData}
        </Flex>
      </Box>

      <FilterModal
        isAddFilterOpen={isAddFilterOpen}
        onAddFilterClose={onAddFilterClose}
        setFilters={setFilters}
        filters={filters}
        handleResetFilters={handleResetFilters}
      />
    </Flex>
  )
}

export default VehiclesOverview
