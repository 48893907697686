import { AspectRatio, Divider, Flex, Grid, useDisclosure } from '@chakra-ui/react'
import DownChevronIcon from '../icons/DownChevronIcon'
import { colors } from '../theme'
import Button from './Button'
import { Add } from '../icons/Add'
import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { Input } from './Input'
import getFieldStatus from '../model/helpers/StatusHelper'
import Delete from '../icons/Delete'
import YoutubeIcon from '../icons/YoutubeIcon'

const VideoDropdown = ({
  videoIds,
  mode,
  setFieldValue,
  errors,
  touched,
  values,
}: {
  videoIds: any
  mode: string
  setFieldValue?: any
  errors?: any
  touched?: any
  values?: any
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [count, setCount] = useState<string[]>([])
  const handleOpenAndClose = () => {
    if (isOpen) {
      onClose()
    }
    if (!isOpen) {
      onOpen()
    }
  }

  return (
    <>
      <Flex
        w={'100%'}
        h={'3rem'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        justifyContent={'space-between'}
        onClick={handleOpenAndClose}
        cursor={'pointer'}
      >
        <Grid placeItems={'center'} w={'80%'} fontSize={'0.8rem'} fontWeight={'semibold'}>
          Videá priebehu servisu
        </Grid>
        <Grid placeItems={'center'} w={'20%'}>
          <DownChevronIcon fontSize={'1rem'} transform={isOpen && 'rotate(180deg)'} />
        </Grid>
      </Flex>
      {isOpen && (
        <Flex flexFlow={'column'} gap={'0.8rem'} w={{ sm: '100%', md: 'initial' }}>
          {mode === 'edit' &&
            videoIds &&
            videoIds.map((item: any, index: number) => {
              return (
                <React.Fragment key={item.link}>
                  <AspectRatio w={'100%'} ratio={16 / 9}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${item.link}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen={true}
                    ></iframe>
                  </AspectRatio>

                  <Button
                    variant={'red'}
                    size={'full'}
                    onClick={() => {
                      setCount((prev) => prev.filter((item, i) => i !== index))
                      const updatedVideoObject = [...values[`serviceVideos`]]
                      updatedVideoObject.splice(index, 1)
                      setFieldValue(`serviceVideos`, updatedVideoObject)
                    }}
                  >
                    <Delete />
                  </Button>
                  <Divider
                    borderWidth={'0.2rem 0'}
                    borderColor={colors.gray[700]}
                    bg={colors.gray[700]}
                  />
                </React.Fragment>
              )
            })}

          {count.map((item: any, index) => {
            return (
              <>
                <Field
                  bg={colors.blue[100]}
                  name={`videoObject[${index}]`}
                  label={'YouTube link'}
                  setFieldValue={setFieldValue}
                  component={Input}
                  status={getFieldStatus(errors, touched, `videoObject[${index}]`)}
                />
                <Button
                  variant={'red'}
                  size={'full'}
                  onClick={() => {
                    setCount((prev) => prev.filter((item, i) => i !== index))
                    const updatedVideoObject = [...values[`videoObject`]]
                    updatedVideoObject.splice(index, 1)
                    setFieldValue(`videoObject`, updatedVideoObject)
                  }}
                >
                  <Delete />
                </Button>
                {index !== count.length - 1 && (
                  <Divider
                    borderWidth={'0.2rem 0'}
                    borderColor={colors.gray[700]}
                    bg={colors.gray[700]}
                  />
                )}
              </>
            )
          })}

          {mode === 'viewOnly' &&
            videoIds &&
            videoIds.map((item: any, index: number) => {
              return (
                <>
                  <AspectRatio w={'100%'} ratio={16 / 9}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={`https://www.youtube.com/embed/${item.link}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen={true}
                    ></iframe>
                  </AspectRatio>
                  {videoIds.length - 1 !== index && (
                    <Divider
                      borderWidth={'0.2rem 0'}
                      borderColor={colors.gray[700]}
                      bg={colors.gray[700]}
                    />
                  )}
                </>
              )
            })}

          {mode !== 'viewOnly' && (
            <Button
              size={'large'}
              variant={'blue'}
              w={'100%'}
              h={'5rem'}
              p={'unset'}
              onClick={() => setCount((prev) => [...prev, 'new'])}
            >
              <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'}>
                <Add fontSize={'2rem'} />
                <YoutubeIcon fontSize={'2rem'} />
              </Flex>
              pridať video
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}

export default VideoDropdown
