import { Box } from './Box'
import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import PersonIcon from '../icons/PersonIcon'
import { colors } from '../theme'
import IconButton from './IconButton'
import Delete from '../icons/Delete'
import Edit from '../icons/Edit'
import DeleteModal from './DeleteModal'
import { useDeleteVehicleOwner } from '../model/api/useDeleteVehicleOwner'
import { format } from 'date-fns'
import CustomerDetailModal from './CustomerDetailModal'
import CustomerForm from '../pages/customerManagement/CustomerForm'
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import Close from '../icons/Close'
import ApiRouteEnum from '../model/enums/ApiRouteEnum'
import { useQueryClient } from 'react-query'
import { useCustomToast } from './Toast'
import useIsMobile from '../model/helpers/useIsMobile'
import Menu from './Menu'
import { useOutletContext } from 'react-router-dom'

const CustomerCard = ({ item }: { item: any }) => {
  const query = useQueryClient()
  const { totalBlock } = useOutletContext() as any
  const isMobile = useIsMobile()
  const { showCustomToast } = useCustomToast()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isCustomerDetailModalOpen,
    onOpen: onCustomerDetailModalOpen,
    onClose: onCustomerDetailModalClose,
  } = useDisclosure()
  const {
    isOpen: isEditCustomerModalOpen,
    onOpen: onEditCustomerModalOpen,
    onClose: onEditCustomerModalClose,
  } = useDisclosure()
  const mutateDelete = useDeleteVehicleOwner()
  return (
    <Box w={{ sm: '90%', md: '30%' }}>
      <Box w={'100%'} pb={5} bg={colors.blue[100]}>
        <Flex alignItems={'center'}>
          <Box w={'3rem'} h={'3rem'} bg={colors.gray[700]} display={'grid'} placeItems={'center'}>
            <PersonIcon color={colors.blue[500]} />
          </Box>
          <Box fontWeight={'semibold'} w={'80%'} textAlign={'center'}>
            {item.firstname + ' ' + item.lastname}
          </Box>
        </Flex>
        <br />
        <Flex
          pl={'1rem'}
          gap={'0.8rem'}
          flexFlow={'column'}
          onClick={!totalBlock ? () => onCustomerDetailModalOpen() : () => {}}
          cursor={'pointer'}
        >
          <Box>
            <Box fontWeight={'semibold'} mb={'5px'}>
              Email:
            </Box>
            <Box>{item.contactEmail || '-'}</Box>
          </Box>
          <Box>
            <Box fontWeight={'semibold'} mb={'5px'}>
              Telefón:
            </Box>
            <Box>{item.phoneNumber || '-'}</Box>
          </Box>
          <Box>
            <Box fontWeight={'semibold'} mb={'5px'}>
              Dátum narodenia:
            </Box>
            <Box>{item.birthdate ? format(new Date(item.birthdate), 'dd.MM.yyyy') : '-'}</Box>
          </Box>
        </Flex>
        <br />
        <Flex w={'100%'} justifyContent={'space-evenly'}>
          <IconButton
            isDisabled={totalBlock}
            variant={'blue'}
            icon={<Edit />}
            size={'half'}
            onClick={() => onEditCustomerModalOpen()}
          />
          <IconButton
            isDisabled={totalBlock}
            variant={'red'}
            icon={<Delete />}
            size={'half'}
            onClick={() => onDeleteModalOpen()}
          />
        </Flex>
      </Box>

      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        onDeleteModalClose={onDeleteModalClose}
        titleValue={item.firstname + ' ' + item.lastname}
        onClickAction={() => {
          mutateDelete.mutate(item.id, {
            onSuccess: () => {
              query.invalidateQueries(ApiRouteEnum.VEHICLE_OWNER)
              query.invalidateQueries('workspaceByQuotas')
              onDeleteModalClose()
              showCustomToast({
                status: 'success',
                title: 'Položka úspešne odstránená',
                label: (
                  <div>
                    Zákazník <b>{item.firstname + ' ' + item.lastname}</b> bol úspešne odstránený z
                    evidencie
                  </div>
                ),
              })
            },
            onError: () => {
              onDeleteModalClose()
              showCustomToast({
                status: 'error',
                title: 'Položku sa nepodarilo odstrániť',
                label: (
                  <div>
                    Zákazníka <b>{item.firstname + ' ' + item.lastname}</b> sa nepodarilo odstrániť
                  </div>
                ),
              })
            },
          })
        }}
      />

      <CustomerDetailModal
        customer={item}
        isCustomerDetailModalOpen={isCustomerDetailModalOpen}
        onCustomerDetailModalClose={onCustomerDetailModalClose}
        editClick={() => onEditCustomerModalOpen()}
      />

      {isMobile ? (
        <Modal
          size={'full'}
          isOpen={isEditCustomerModalOpen}
          onClose={onEditCustomerModalClose}
          isCentered={true}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody w={'100%'} p={0}>
              <Grid
                fontSize={'1.2rem'}
                fontWeight={'semibold'}
                w={'100%'}
                h={'4rem'}
                placeItems={'center'}
                bg={colors.gray[700]}
                color={colors.blue[100]}
                zIndex={100}
                position={'sticky'}
                top={'0px'}
              >
                Evidencia a spravovanie zákazníkov
              </Grid>
              <CustomerForm customer={item} onCreateCustomerModalClose={onEditCustomerModalClose} />
              <Menu type={'basic'} />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          size={'xl'}
          isOpen={isEditCustomerModalOpen}
          onClose={onEditCustomerModalClose}
          isCentered={true}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display={'flex'} alignItems={'center'} p={0}>
              <PersonIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} />{' '}
              upraviť zákazníka
              <Close
                fontSize={'1.6rem'}
                bg={colors.red[500]}
                ml={'auto'}
                cursor={'pointer'}
                onClick={() => onEditCustomerModalClose()}
                p={4}
              />
            </ModalHeader>
            <ModalBody>
              <CustomerForm customer={item} onCreateCustomerModalClose={onEditCustomerModalClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  )
}

export default CustomerCard
