import { Flex, Grid } from '@chakra-ui/layout'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { useDisclosure, Checkbox } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useQueryClient } from 'react-query'
import { useDeleteService } from '../../../model/api/useDeleteService'
import { useCustomToast } from '../../../components/Toast'
import useGetWorkspaceById from '../../../model/api/useGetWorkspaceById'
import { colors } from '../../../theme'
import Settings from '../../../icons/Settings'
import { Box } from '../../../components/Box'
import { DisabledInput } from '../../../components/DisabledInput'
import { Input } from '../../../components/Input'
import Textarea from '../../../components/Textarea'
import Calendar from '../../../icons/Calendar'
import Button from '../../../components/Button'
import EuroIcon from '../../../icons/EuroIcon'
import { Select } from '../../../components/Select'
import GaugeIcon from '../../../icons/GaugeIcon'
import PhotoDropdown from '../../../components/PhotoDropdown'
import RouterEnum from '../../../model/enums/RouterEnum'
import BottomSideMenu from '../../../components/BottomSideMenu '
import DeleteModal from '../../../components/DeleteModal'
import ApiRouteEnum from '../../../model/enums/ApiRouteEnum'
import ShareModal from '../../../components/ShareModal'
import EmailModal from '../../../components/EmailModal'
import Menu from '../../../components/Menu'
import PriceModalMobile from '../../../components/PriceModalMobile'
import VideoDropdown from '../../../components/VideoDropdown'
import { useEffect } from 'react'

const ServiceDetailMobile = () => {
  const mutateDelete = useDeleteService()
  const { totalBlock } = useOutletContext() as any
  const { showCustomToast } = useCustomToast()
  const query = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const item = location.state ? location.state.item : undefined
  const car = location.state ? location.state.car : undefined
  const { data: workspaceData } = useGetWorkspaceById(car?.workspace?.id)
  const statusDisplayNames = {
    received: 'Prijaté do servisu',
    in_progress: 'Započaté práce',
    ready_to_return: 'Pripravené na vrátenie majiteľovi',
    returned: 'Vrátené majiteľovi',
  }
  const {
    isOpen: isPriceModalOpen,
    onOpen: onPriceModalOpen,
    onClose: onPriceModalClose,
  } = useDisclosure()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isShareModalOpen,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure()
  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure()

  useEffect(() => {
    if (totalBlock) {
      navigate(RouterEnum.WORKSPACE_MANAGEMENT + '/' + RouterEnum.WORKSPACE_LIMITS_SETTINGS)
    }
  }, [totalBlock])

  if (location.state && !item) {
    return null
  }

  return (
    <Flex
      alignItems={'center'}
      h={'100%'}
      flexFlow={'column'}
      overflow={'auto'}
      bg={colors.blue[50]}
    >
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'4rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Prehľad servisu
      </Grid>
      <Flex h={'80%'} w={'100%'} flexFlow={'column'} alignItems={'center'}>
        <Flex w={'85%'} m={'1rem 0'} justifyContent={'flex-start'}>
          <Grid w={'3rem'} h={'3rem'} bg={colors.gray[700]} placeItems={'center'}>
            <Settings color={colors.blue[500]} />
          </Grid>
          <Box
            w={'50%'}
            m={'0 auto'}
            fontSize={'1rem'}
            textAlign={'center'}
            fontWeight={'semibold'}
          >
            {item.displayName}
          </Box>
        </Flex>
        <Flex
          w={'85%'}
          gap={'1rem'}
          justifyContent={'space-between'}
          flexFlow={'column'}
          pb={'11rem'}
        >
          <Button
            variant={'transparent'}
            onClick={() =>
              navigate('/' + RouterEnum.VEHICLE_MANAGEMENT + '/' + RouterEnum.VEHICLE_DETAIL, {
                state: { car: car },
              })
            }
          >
            detail vozidla
          </Button>
          <DisabledInput
            bg={colors.blue[100]}
            label={'VIN'}
            defaultValue={car.vinCode}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            label={'SPZ'}
            defaultValue={car.licensePlate}
            component={Input}
          />
          <DisabledInput
            bg={colors.blue[100]}
            label={'majiteľ'}
            defaultValue={`${car?.vehicleOwner?.firstname} ${car?.vehicleOwner?.lastname}`}
            component={Input}
          />
          <Textarea
            placeholder={
              item.vehicleOwnerDescription && item.vehicleOwnerDescription.description !== null
                ? item.vehicleOwnerDescription.description
                : ''
            }
            label={'popis servisu'}
            isReadOnly
            helperText={'Obsah sa zobrazí majiteľovi vozidla pri zdieľaní'}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="estimatedServiceTime"
            label={'dĺžka práce'}
            defaultValue={item.estimatedServiceTime ? `${item.estimatedServiceTime} hod` : '-'}
            type={'number'}
            component={Input}
          />
          <Box m={0} fontSize={'0.8rem'} fontWeight={'semibold'}>
            Odhadovaný dátum dokončenia
            <Flex
              h={'3rem'}
              bg={colors.blue[100]}
              alignItems={'center'}
              fontSize={'0.8rem'}
              fontWeight={'semibold'}
            >
              <Calendar ml={'0.4rem'} fontSize={'1rem'} color={colors.blue[500]} />
              <Box m={'auto'}>
                {item.estimatedReturnedAt
                  ? format(new Date(item.estimatedReturnedAt), 'dd.MM.yyyy')
                  : 'xx.xx.xxxx'}
              </Box>
            </Flex>
          </Box>
          <Button size={'full'} variant={'blue'} onClick={() => onPriceModalOpen()}>
            <EuroIcon fontSize={'1rem'} />
            Nastavenie ceny
          </Button>
          {item.vehicleWorkDescription?.checkList?.length > 0 ? (
            <Flex flexFlow={'column'} bg={colors.blue[100]} minH={'11rem'} gap={'1rem'} pb={'1rem'}>
              <Grid
                fontSize={'0.8rem'}
                h={'3rem'}
                bg={colors.gray[700]}
                color={colors.blue[100]}
                fontWeight={'semibold'}
                placeItems={'center'}
              >
                zadanie - servis
              </Grid>
              {item.vehicleWorkDescription.checkList.map((element: any, index: number) => {
                return (
                  <Flex ml={'0.5rem'} alignItems={'center'}>
                    <Checkbox isChecked={element.isChecked} isReadOnly mr={'1rem'} />
                    <Box fontWeight={'semibold'} fontSize={'0.8rem'}>
                      {element.label}
                    </Box>
                  </Flex>
                )
              })}
            </Flex>
          ) : (
            <Textarea
              label={'zadanie - servis'}
              isReadOnly
              placeholder={
                item.vehicleWorkDescription?.description
                  ? item.vehicleWorkDescription.description
                  : ''
              }
            />
          )}

          {item.vehicleMaterialDescription?.checkList?.length > 0 ? (
            <Flex flexFlow={'column'} bg={colors.blue[100]} minH={'11rem'} gap={'1rem'} pb={'1rem'}>
              <Grid
                fontSize={'0.8rem'}
                h={'3rem'}
                bg={colors.gray[700]}
                color={colors.blue[100]}
                fontWeight={'semibold'}
                placeItems={'center'}
              >
                zadanie - servis
              </Grid>
              {item.vehicleMaterialDescription.checkList.map((element: any, index: number) => {
                return (
                  <>
                    <Flex ml={'0.5rem'} alignItems={'center'}>
                      <Checkbox isChecked={element.isChecked} isReadOnly mr={'1rem'} />
                      <Box fontWeight={'semibold'} fontSize={'0.8rem'}>
                        {element.label}
                      </Box>
                    </Flex>
                  </>
                )
              })}
            </Flex>
          ) : (
            <Textarea
              label={'zadanie - materiály'}
              isReadOnly
              placeholder={
                item.vehicleMaterialDescription?.description
                  ? item.vehicleMaterialDescription.description
                  : ''
              }
            />
          )}
          <Select
            label={'Stav servisu'}
            title={statusDisplayNames[item.status.statusName as keyof typeof statusDisplayNames]}
            isReadOnly
          />
          <Box m={0} fontSize={'0.8rem'} fontWeight={'semibold'}>
            Prevzatie
            <Grid templateColumns={'repeat(2, 1fr)'} gap={3}>
              <Flex
                h={'3rem'}
                bg={colors.blue[100]}
                justifyContent={'space-around'}
                alignItems={'center'}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                {format(new Date(item.receivedAt), 'dd.MM.yyyy')}
              </Flex>
              <Flex
                h={'3rem'}
                bg={colors.blue[100]}
                justifyContent={'space-around'}
                alignItems={'center'}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                <GaugeIcon fontSize={'1rem'} color={colors.blue[500]} />
                {item.mileageAtReceive ? item.mileageAtReceive + ' KM' : 'XXX KM'}
              </Flex>
            </Grid>
          </Box>
          <PhotoDropdown mode={'viewOnly'} type={'service'} />
          <VideoDropdown mode={'viewOnly'} videoIds={item.serviceVideos} />
          <Box m={0} fontSize={'0.8rem'} fontWeight={'semibold'}>
            Odovzdanie
            <Grid templateColumns={'repeat(2, 1fr)'} gap={3}>
              <Flex
                h={'3rem'}
                bg={colors.blue[100]}
                justifyContent={'space-around'}
                alignItems={'center'}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                {item.returnedAt ? format(new Date(item.receivedAt), 'dd.MM.yyyy') : 'xx.xx.xxxx'}
              </Flex>
              <Flex
                h={'3rem'}
                bg={colors.blue[100]}
                justifyContent={'space-around'}
                alignItems={'center'}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                <GaugeIcon fontSize={'1rem'} color={colors.blue[500]} />
                {item.mileageAtReturn ? item.mileageAtReturn + ' KM' : 'XXX KM'}
              </Flex>
            </Grid>
          </Box>
          <PhotoDropdown mode={'viewOnly'} type={'costumer'} />
        </Flex>
      </Flex>
      <Menu back={() => navigate(-1)} type={'basic'} />

      <BottomSideMenu
        blueClickEdit={() => {
          navigate(
            '/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_EDIT,
            {
              state: { item: item, car: { label: car.displayName, value: car.id }, mode: 'edit' },
            },
          )
        }}
        darkClickShare={() =>
          navigate(RouterEnum.SHARE_MENU, {
            state: {
              vehicleServiceId: item.id,
              priceSharing: item.vehicleServiceSharingSettings,
            },
          })
        }
        darkClickEmail={() => onEmailModalOpen()}
        redClick={() => {
          onDeleteModalOpen()
        }}
      />

      <PriceModalMobile
        isPriceModalOpen={isPriceModalOpen}
        onPriceModalClose={onPriceModalClose}
        values={item}
        isReadOnly={true}
      />

      <DeleteModal
        titleValue={item.displayName}
        isDeleteModalOpen={isDeleteModalOpen}
        onDeleteModalClose={onDeleteModalClose}
        onClickAction={() => {
          mutateDelete.mutate(item.id, {
            onSuccess: () => {
              query.invalidateQueries(ApiRouteEnum.VEHICLE_SERVIS)
              navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW)
              showCustomToast({
                status: 'success',
                title: 'Položka úspešne odstránená',
                label: (
                  <div>
                    Servis <b>{item.displayName}</b> bol úspešne odstránený z evidencie
                  </div>
                ),
              })
            },
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Položku sa nepodarilo odstrániť',
                label: (
                  <div>
                    Servis <b>{item.displayName}</b> sa nepodarilo odstrániť
                  </div>
                ),
              })
            },
          })
        }}
      />

      <ShareModal
        isShareModalOpen={isShareModalOpen}
        onShareModalClose={onShareModalClose}
        vehicleServiceId={item.id}
        priceSharing={item.vehicleServiceSharingSettings}
      />

      <EmailModal
        workspaceData={workspaceData}
        vehicleServiceId={item.id}
        isEmailModalOpen={isEmailModalOpen}
        onEmailModalClose={onEmailModalClose}
        status={item.status.statusName}
        email={car.vehicleOwner?.contactEmail}
      />
    </Flex>
  )
}

export default ServiceDetailMobile
