enum ApiRouteEnum {
  ACTIVATE = '/activate',
  CHANGE_PASSWORD = '/change-password',
  FORGOTTEN_PASSWORD = '/public/api/v1/user/restart-password',
  CODE = '/api/v1/sharing/vehicle-service',
  IMAGE = '/api/v1/image',
  IMAGE_RECOGNITION = '/api/v1/image-recognition',
  LOGIN = '/public/api/v1/login',
  ME = '/api/v1/me',
  NOTIFICATION = '/notification',
  PAYMENT= '/api/v1/workspace/payment',
  RESTART_PASSWORD = '/restart-password',
  SHARE_SETTINGS = '/api/v1/vehicle-service/share-settings',
  STATUS = 'status',
  USER = '/api/v1/user',
  USER_LINK = '/public/api/v1/user-link',
  VEHICLE = '/api/v1/vehicle',
  VEHICLE_INSURANCE = '/api/v1/vehicle-insurance',
  VEHICLE_MOT = '/api/v1/vehicle-mot',
  VEHICLE_OWNER = '/api/v1/vehicle-owner',
  VEHICLE_OWNER_NOTE = '/api/v1/vehicle-owner-note',
  VEHICLE_SERVIS = '/api/v1/vehicle-service',
  WORKSPACE = '/public/api/v1/workspace',
  WORKSPACE_BY_ID = '/api/v1/workspace',
  EMAIL_AVAILABLE = '/public/api/v1/user/available',
}
export default ApiRouteEnum
