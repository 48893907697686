import { Flex, Grid } from '@chakra-ui/layout'
import { Input } from '../../components/Input'
import { Select } from '../../components/Select'
import { colors } from '../../theme'
import Textarea from '../../components/Textarea'
import { Box } from '../../components/Box'
import RightSideMenu from '../../components/RightSideMenu'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import RouterEnum from '../../model/enums/RouterEnum'
import Settings from '../../icons/Settings'
import EuroIcon from '../../icons/EuroIcon'
import Button from '../../components/Button'
import PhotoDropdown from '../../components/PhotoDropdown'
import { useDisclosure, Checkbox } from '@chakra-ui/react'
import { DisabledInput } from '../../components/DisabledInput'
import { useDeleteService } from '../../model/api/useDeleteService'
import { format } from 'date-fns'
import Calendar from '../../icons/Calendar'
import GaugeIcon from '../../icons/GaugeIcon'
import ShareModal from '../../components/ShareModal'
import DeleteModal from '../../components/DeleteModal'
import PriceModal from '../../components/PriceModal'
import EmailModal from '../../components/EmailModal'
import useGetWorkspaceById from '../../model/api/useGetWorkspaceById'
import { useCustomToast } from '../../components/Toast'
import ApiRouteEnum from '../../model/enums/ApiRouteEnum'
import { useQueryClient } from 'react-query'
import VideoDropdown from '../../components/VideoDropdown'
import { useEffect } from 'react'

const ServiceDetail = () => {
  const mutateDelete = useDeleteService()
  const { totalBlock } = useOutletContext() as any
  const { showCustomToast } = useCustomToast()
  const query = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const item = location.state ? location.state.item : undefined
  const car = location.state ? location.state.car : undefined
  const { data: workspaceData } = useGetWorkspaceById(car?.workspace?.id)
  const statusDisplayNames = {
    received: 'Prijaté do servisu',
    in_progress: 'Započaté práce',
    ready_to_return: 'Pripravené na vrátenie majiteľovi',
    returned: 'Vrátené majiteľovi',
  }
  const {
    isOpen: isPriceModalOpen,
    onOpen: onPriceModalOpen,
    onClose: onPriceModalClose,
  } = useDisclosure()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isShareModalOpen,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure()
  const {
    isOpen: isEmailModalOpen,
    onOpen: onEmailModalOpen,
    onClose: onEmailModalClose,
  } = useDisclosure()

  useEffect(() => {
    if (totalBlock) {
      navigate(RouterEnum.WORKSPACE_MANAGEMENT + '/' + RouterEnum.WORKSPACE_LIMITS_SETTINGS)
    }
  }, [totalBlock])

  if (location.state && item === undefined && car === undefined) {
    return null
  }
  const boxWidth = '16rem'
  return (
    <Flex alignItems={'center'} h={'100%'} flexFlow={'column'} overflow={'auto'} pb={'3rem'}>
      <Flex w={'65%'} m={'1rem 0'} justifyContent={'flex-start'}>
        <Grid w={'3rem'} h={'3rem'} bg={colors.gray[700]} placeItems={'center'}>
          <Settings color={colors.blue[500]} />
        </Grid>
        <Box w={'50%'} m={'0 auto'} fontSize={'2rem'} textAlign={'center'} fontWeight={'semibold'}>
          {item.displayName}
        </Box>
      </Flex>
      <Flex w={'65%'} gap={'1rem'} justifyContent={'space-between'}>
        <Flex flexFlow={'column'} w={boxWidth} gap={'1rem'}>
          <DisabledInput bg={colors.blue[100]} label={'VIN'} defaultValue={car.vinCode} />
          <DisabledInput bg={colors.blue[100]} label={'SPZ'} defaultValue={car.licensePlate} />
          <DisabledInput
            bg={colors.blue[100]}
            label={'majiteľ'}
            defaultValue={`${car.vehicleOwner?.firstname} ${car.vehicleOwner?.lastname}`}
          />
          <Textarea
            placeholder={
              item.vehicleOwnerDescription && item.vehicleOwnerDescription.description !== null
                ? item.vehicleOwnerDescription.description
                : ''
            }
            label={'popis servisu'}
            isReadOnly
            helperText={'Obsah sa zobrazí majiteľovi vozidla pri zdieľaní'}
          />
          <DisabledInput
            bg={colors.blue[100]}
            name="estimatedServiceTime"
            label={'dĺžka práce'}
            defaultValue={item.estimatedServiceTime ? `${item.estimatedServiceTime} hod` : '-'}
            type={'number'}
          />
          <Box m={0} fontSize={'0.8rem'} fontWeight={'semibold'}>
            Odhadovaný dátum dokončenia
            <Flex
              h={'3rem'}
              bg={colors.blue[100]}
              alignItems={'center'}
              fontSize={'0.8rem'}
              fontWeight={'semibold'}
            >
              <Calendar ml={'0.4rem'} fontSize={'1rem'} color={colors.blue[500]} />
              <Box m={'auto'}>
                {item.estimatedReturnedAt
                  ? format(new Date(item.estimatedReturnedAt), 'dd.MM.yyyy')
                  : '-'}
              </Box>
            </Flex>
          </Box>
          <Button size={'full'} variant={'blue'} onClick={() => onPriceModalOpen()}>
            <EuroIcon fontSize={'1rem'} />
            Nastavenie ceny
          </Button>
        </Flex>
        <Flex flexFlow={'column'} w={boxWidth} gap={'1rem'}>
          {item.vehicleWorkDescription?.checkList?.length > 0 ? (
            <Flex flexFlow={'column'} bg={colors.blue[100]} minH={'11rem'} gap={'1rem'} pb={'1rem'}>
              <Grid
                fontSize={'0.8rem'}
                h={'3rem'}
                bg={colors.gray[700]}
                color={colors.blue[100]}
                fontWeight={'semibold'}
                placeItems={'center'}
              >
                zadanie - servis
              </Grid>
              {item.vehicleWorkDescription.checkList.map((element: any, index: number) => {
                return (
                  <Flex ml={'0.5rem'} alignItems={'center'}>
                    <Checkbox isChecked={element.isChecked} isReadOnly mr={'1rem'} />
                    <Box fontWeight={'semibold'} fontSize={'0.8rem'}>
                      {element.label}
                    </Box>
                  </Flex>
                )
              })}
            </Flex>
          ) : (
            <Textarea
              label={'zadanie - servis'}
              isReadOnly
              placeholder={
                item.vehicleWorkDescription?.description
                  ? item.vehicleWorkDescription.description
                  : ''
              }
            />
          )}

          {item.vehicleMaterialDescription?.checkList?.length > 0 ? (
            <Flex flexFlow={'column'} bg={colors.blue[100]} minH={'11rem'} gap={'1rem'} pb={'1rem'}>
              <Grid
                fontSize={'0.8rem'}
                h={'3rem'}
                bg={colors.gray[700]}
                color={colors.blue[100]}
                fontWeight={'semibold'}
                placeItems={'center'}
              >
                zadanie - servis
              </Grid>
              {item.vehicleMaterialDescription.checkList.map((element: any, index: number) => {
                return (
                  <>
                    <Flex ml={'0.5rem'} alignItems={'center'}>
                      <Checkbox isChecked={element.isChecked} isReadOnly mr={'1rem'} />
                      <Box fontWeight={'semibold'} fontSize={'0.8rem'}>
                        {element.label}
                      </Box>
                    </Flex>
                  </>
                )
              })}
            </Flex>
          ) : (
            <Textarea
              label={'zadanie - materiály'}
              isReadOnly
              placeholder={
                item.vehicleMaterialDescription?.description
                  ? item.vehicleMaterialDescription.description
                  : ''
              }
            />
          )}
        </Flex>
        <Flex flexFlow={'column'} w={boxWidth} gap={'1rem'}>
          <Select
            label={'Stav servisu'}
            title={statusDisplayNames[item.status.statusName as keyof typeof statusDisplayNames]}
            isReadOnly
          />
          <Box m={0} fontSize={'0.8rem'} fontWeight={'semibold'}>
            Prevzatie
            <Grid templateColumns={'repeat(2, 1fr)'} gap={3}>
              <Flex
                h={'3rem'}
                bg={colors.blue[100]}
                justifyContent={'space-around'}
                alignItems={'center'}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                {format(new Date(item.receivedAt), 'dd.MM.yyyy')}
              </Flex>
              <Flex
                h={'3rem'}
                bg={colors.blue[100]}
                justifyContent={'space-around'}
                alignItems={'center'}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                <GaugeIcon fontSize={'1rem'} color={colors.blue[500]} />
                {item.mileageAtReceive ? item.mileageAtReceive + ' KM' : '- KM'}
              </Flex>
            </Grid>
          </Box>
          <PhotoDropdown mode={'viewOnly'} photoIds={item.photosAtReceive} type={'Service'} />
          <VideoDropdown mode={'viewOnly'} videoIds={item.serviceVideos} />
          <Box m={0} fontSize={'0.8rem'} fontWeight={'semibold'}>
            Odovzdanie
            <Grid templateColumns={'repeat(2, 1fr)'} gap={3}>
              <Flex
                h={'3rem'}
                bg={colors.blue[100]}
                justifyContent={'space-around'}
                alignItems={'center'}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                <Calendar fontSize={'1rem'} color={colors.blue[500]} />
                {item.returnedAt ? format(new Date(item.receivedAt), 'dd.MM.yyyy') : '-'}
              </Flex>
              <Flex
                h={'3rem'}
                bg={colors.blue[100]}
                justifyContent={'space-around'}
                alignItems={'center'}
                fontSize={'0.8rem'}
                fontWeight={'semibold'}
              >
                <GaugeIcon fontSize={'1rem'} color={colors.blue[500]} />
                {item.mileageAtReturn ? item.mileageAtReturn + ' KM' : '- KM'}
              </Flex>
            </Grid>
          </Box>
          <PhotoDropdown mode={'viewOnly'} photoIds={item.photosAtReturn} type={'Customer'} />
          <Button
            variant={'transparent'}
            onClick={() =>
              navigate('/' + RouterEnum.VEHICLE_MANAGEMENT + '/' + RouterEnum.VEHICLE_DETAIL, {
                state: { car: car },
              })
            }
          >
            detail vozidla
          </Button>
        </Flex>
      </Flex>

      <RightSideMenu
        blueClickEdit={() => {
          navigate(
            '/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_EDIT,
            {
              state: { item: item, car: { label: car.displayName, value: car.id }, mode: 'edit' },
            },
          )
        }}
        darkClickShare={() => onShareModalOpen()}
        darkClickEmail={() => onEmailModalOpen()}
        redClick={() => {
          onDeleteModalOpen()
        }}
      />

      <PriceModal
        isPriceModalOpen={isPriceModalOpen}
        onPriceModalClose={onPriceModalClose}
        values={item}
        isReadOnly={true}
      />

      <DeleteModal
        titleValue={item.displayName}
        isDeleteModalOpen={isDeleteModalOpen}
        onDeleteModalClose={onDeleteModalClose}
        onClickAction={() => {
          mutateDelete.mutate(item.id, {
            onSuccess: () => {
              query.invalidateQueries(ApiRouteEnum.VEHICLE_SERVIS)
              navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW)
              showCustomToast({
                status: 'success',
                title: 'Položka úspešne odstránená',
                label: (
                  <div>
                    Servis <b>{item.displayName}</b> bol úspešne odstránený z evidencie
                  </div>
                ),
              })
            },
            onError: () => {
              showCustomToast({
                status: 'error',
                title: 'Položku sa nepodarilo odstrániť',
                label: (
                  <div>
                    Servis <b>{item.displayName}</b> sa nepodarilo odstrániť
                  </div>
                ),
              })
            },
          })
        }}
      />

      <ShareModal
        isShareModalOpen={isShareModalOpen}
        onShareModalClose={onShareModalClose}
        vehicleServiceId={item.id}
        priceSharing={item.vehicleServiceSharingSettings}
      />

      <EmailModal
        workspaceData={workspaceData}
        vehicleServiceId={item.id}
        isEmailModalOpen={isEmailModalOpen}
        onEmailModalClose={onEmailModalClose}
        status={item.status.statusName}
        email={car.vehicleOwner?.contactEmail}
      />
    </Flex>
  )
}

export default ServiceDetail
