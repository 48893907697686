import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Box } from '../../components/Box'
import Button from '../../components/Button'
import { Add } from '../../icons/Add'
import { colors } from '../../theme'
import PersonIcon from '../../icons/PersonIcon'
import CustomerCard from '../../components/CustomerCard'
import useGetAllVehicleOwners from '../../model/api/useGetAllVehicleOwners'
import Close from '../../icons/Close'
import CustomerForm from './CustomerForm'
import Spinner from '../../components/Spinner'
import { useOutletContext } from 'react-router-dom'

const CustomersOverview = () => {
  const { data, isLoading } = useGetAllVehicleOwners()
  const { totalBlock } = useOutletContext() as any

  const {
    isOpen: isCreateCustomerModalOpen,
    onOpen: onCreateCustomerModalOpen,
    onClose: onCreateCustomerModalClose,
  } = useDisclosure()

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      alignItems={'center'}
      flexFlow={'column'}
      overflow={'auto'}
      padding={'1%'}
    >
      <Box w={'75%'}>
        <Box
          fontSize={'1.8rem'}
          fontWeight={'semibold'}
          w={'100%'}
          textAlign={'center'}
          m={'1rem 0'}
        >
          Správa zákazníkov
        </Box>
        <Flex mt={'2rem'} flexFlow={'row wrap'} gap={'1rem'}>
          <Button
            size="large"
            variant="basic"
            w={'30%'}
            h={'unset'}
            bg={colors.blue[100]}
            onClick={onCreateCustomerModalOpen}
            isDisabled={totalBlock}
          >
            <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
              <Add fontSize={'2rem'} />
              <PersonIcon fontSize={'2rem'} />
            </Flex>
            pridať zákazníka
          </Button>

          {data &&
            data.map((item: any) => {
              return <CustomerCard item={item} key={item.id} />
            })}
        </Flex>
      </Box>

      <Modal
        size={'xl'}
        isOpen={isCreateCustomerModalOpen}
        onClose={onCreateCustomerModalClose}
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display={'flex'} alignItems={'center'} p={0}>
            <PersonIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} /> nový
            zákazník{' '}
            <Close
              fontSize={'1.6rem'}
              bg={colors.red[500]}
              ml={'auto'}
              cursor={'pointer'}
              onClick={() => onCreateCustomerModalClose()}
              p={4}
            />
          </ModalHeader>
          <ModalBody>
            <CustomerForm onCreateCustomerModalClose={onCreateCustomerModalClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default CustomersOverview
