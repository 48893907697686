import { Box, Flex, Grid } from '@chakra-ui/react'
import { colors } from '../theme'
import React, { useEffect, useRef } from 'react'
import Button from './Button'

interface Subscription {
  amount: number
  tier: {
    maxUsers: number
    maxVehicles: number
    diskSize: number
    maximumEmailsPerMonth: number
    maxApiCallsPerMonth: number
  }
}

interface SetSubscription {
  (values: Subscription): void
}

interface PackageInfo {
  tier: string
  price: number
  vehicles: number
  users: number
  emails: number
  data: React.ReactNode
  apiCalls: number
  dataNumber: number
}

const WorkspaceLimitCard = ({
  subscription,
  onSetSubscription,
  packageInfo,
}: {
  subscription: Subscription
  onSetSubscription: SetSubscription
  packageInfo: PackageInfo
}) => {
  useEffect(() => {
    if (subscription.tier.maximumEmailsPerMonth === packageInfo.emails) {
      const updatedSubscription: Subscription = {
        ...subscription,
        amount: packageInfo.price,
      }
      onSetSubscription(updatedSubscription)
    }
  }, [subscription.tier.maximumEmailsPerMonth])

  return (
    <Grid
      flexFlow={'column'}
      gap={'0.8rem'}
      alignItems={'center'}
      justifyContent={'center'}
      bg={colors.blue[100]}
      p={'1rem'}
    >
      <Flex
        fontWeight={'semibold'}
        alignItems={'center'}
        w={'100%'}
        justifyContent={'space-between'}
      >
        <Box fontSize={'1.2rem'} fontWeight={'bold'}>
          {packageInfo.tier}
        </Box>
        <Box fontSize={'1.8rem'} fontWeight={'bold'}>
          {packageInfo.price}€
        </Box>
      </Flex>
      <Grid
        gridTemplateColumns={'repeat(2, 1fr)'}
        gridTemplateRows={'repeat(5, 1fr)'}
        rowGap={'1rem'}
      >
        <Box fontWeight={'bold'} paddingRight={'5px'} flex={1}>
          Počet vozidiel:{' '}
        </Box>
        {packageInfo.vehicles}
        <Box fontWeight={'bold'} paddingRight={'5px'} flex={1}>
          Počet užívateľov:{' '}
        </Box>
        {packageInfo.users}
        <Box fontWeight={'bold'} paddingRight={'5px'} flex={1}>
          Počet e-mailov:{' '}
        </Box>
        {packageInfo.emails}
        <Box fontWeight={'bold'} paddingRight={'5px'} flex={1}>
          Možstvo dát:{' '}
        </Box>
        {packageInfo.data}
        <Box fontWeight={'bold'} paddingRight={'5px'} flex={1}>
          API volania:{' '}
        </Box>
        {packageInfo.apiCalls} (Načítanie VIN / SPZ)
      </Grid>
      <Flex w={'100%'}>
        <Button
          variant={
            subscription.tier.maximumEmailsPerMonth === packageInfo.emails ? 'green' : 'blue'
          }
          onClick={() =>
            onSetSubscription({
              amount: packageInfo.price,
              tier: {
                diskSize: packageInfo.dataNumber,
                maxApiCallsPerMonth: packageInfo.apiCalls,
                maxUsers: packageInfo.users,
                maxVehicles: packageInfo.vehicles,
                maximumEmailsPerMonth: packageInfo.emails,
              },
            })
          }
        >
          Zvoliť
        </Button>
      </Flex>
    </Grid>
  )
}

export default WorkspaceLimitCard
