import { Flex, Grid } from '@chakra-ui/react'
import Menu from '../../../components/Menu'
import useIsMobile from '../../../model/helpers/useIsMobile'
import { useEffect } from 'react'
import RouterEnum from '../../../model/enums/RouterEnum'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { colors } from '../../../theme'
import { Box } from '../../../components/Box'
import useGetWorkspaceById from '../../../model/api/useGetWorkspaceById'
import Spinner from '../../../components/Spinner'
import AddBoxIcon from '../../../icons/AddBoxIcon'
import Button from '../../../components/Button'
import WorkspaceCard from '../../../components/WorkspaceCard'
import CloudIcon from '../../../icons/CloudIcon'
import PaperPlaneIcon from '../../../icons/PaperPlaneIcon'
import PersonIcon from '../../../icons/PersonIcon'
import CarIcon from '../../../icons/CarIcon'
import useGetWorkspaceQuotas from '../../../model/api/useGetWorkspaceQuotas'
import { useCustomToast } from '../../../components/Toast'
import ApiCallIcon from '../../../icons/ApiCallIcon'

const WorkspaceLimitsMobile = () => {
  const navigate = useNavigate()
  const {data} = useOutletContext() as any
  const { data: workspaceData, isLoading } = useGetWorkspaceById(data.workspace.id)
  const { data: workspaceDataQuotas, isLoading: workspaceQuotasIsLoading } = useGetWorkspaceQuotas(
    data.workspace.id,
  )
  const isMobile = useIsMobile()
  const { showCustomToast } = useCustomToast()

  useEffect(() => {
    if (!isMobile) {
      navigate(RouterEnum.HOMEPAGE)
    }
  }, [isMobile])

  if (isLoading || workspaceQuotasIsLoading) {
    return (
      <>
        <Grid
          fontSize={'1.2rem'}
          fontWeight={'semibold'}
          w={'100%'}
          h={'4rem'}
          placeItems={'center'}
          bg={colors.gray[700]}
          color={colors.blue[100]}
          zIndex={100}
          position={'sticky'}
          top={'0px'}
        >
          Nastavenia workspace
        </Grid>
        <Grid h={'40rem'}>
          <Spinner />
        </Grid>
        <Menu type={'basic'} />
      </>
    )
  }

  return (
    <Flex flexFlow={'column'} h={'90%'} w={'100%'} bg={colors.blue[50]}>
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'6rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Nastavenia workspace
        <Box fontSize={'0.9rem'}>
          {data.firstname} {data.lastname}
        </Box>
        <Box fontSize={'0.9rem'} fontWeight={'normal'}>
          {workspaceData.name}
        </Box>
      </Grid>
      <Box h={'40rem'} overflow={'auto'} pb={'10rem'}>
        <Grid
          gridTemplateColumns={'repeat(2, 1fr)'}
          gap={'0.5rem'}
          m={'2rem auto 0.5rem auto'}
          // h={'58%'}
          w={'90%'}
        >
          <WorkspaceCard
            icon={<CarIcon />}
            current={workspaceDataQuotas.vehicle.current}
            maximum={workspaceDataQuotas.vehicle.maximum}
            label={'počet vozidiel'}
          />
          <WorkspaceCard
            icon={<PersonIcon />}
            current={workspaceDataQuotas.users.current}
            maximum={workspaceDataQuotas.users.maximum}
            label={'počet užívateľov'}
          />
          <WorkspaceCard
            icon={<PaperPlaneIcon />}
            current={workspaceDataQuotas.emails.current}
            maximum={workspaceDataQuotas.emails.maximum}
            label={'počet emailov'}
          />
          <WorkspaceCard
            icon={<CloudIcon />}
            current={workspaceDataQuotas.diskSize.current}
            maximum={workspaceDataQuotas.diskSize.maximum}
            label={'množstvo dát'}
          />
          <WorkspaceCard
            icon={<ApiCallIcon />}
            current={workspaceDataQuotas.apiCalls.current}
            maximum={workspaceDataQuotas.apiCalls.maximum}
            label={'API volania'}
          />
          <Button
            variant={'blue'}
            size="medium"
            fontSize={'0.8rem'}
            w={'100%'}
            h={'100%'}
            m={'0 auto'}
            // onClick={() =>
            //   showCustomToast({
            //     status: 'warning',
            //     title: 'Nie je možné zmeniť limity',
            //     label: <div>Táto funkcionalita zatiaľ nie je k dispozícii</div>,
            //   })
            // }
            onClick={() =>
              navigate(
                '/' + RouterEnum.WORKSPACE_MANAGEMENT + '/' + RouterEnum.WORKSPACE_LIMITS_SETTINGS,
              )
            }
          >
            <AddBoxIcon />
            <Box w={'70%'} whiteSpace={'pre-wrap'}>
              zvýšiť limity
            </Box>
          </Button>
        </Grid>
      </Box>
      <Menu back={() => navigate(-1)} type={'basic'} />
    </Flex>
  )
}

export default WorkspaceLimitsMobile
