import { Flex, Grid } from '@chakra-ui/layout'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import { useMemo } from 'react'
import useGetServices from '../../../model/api/useGetServices'
import ServiceCard from '../../../components/ServiceCard'
import Spinner from '../../../components/Spinner'
import Button from '../../../components/Button'
import { colors } from '../../../theme'
import RouterEnum from '../../../model/enums/RouterEnum'
import { Add } from '../../../icons/Add'
import Settings from '../../../icons/Settings'
import Menu from '../../../components/Menu'
import useGetAllCars from '../../../model/api/useGetAllCars'
import { Box } from '../../../components/Box'

const ServicesOverviewMobile = () => {
  const location = useLocation()
  const { totalBlock } = useOutletContext() as any
  const { data, isLoading } = useGetServices(location.state && location.state.car)
  const { data: vehiclesData, isLoading: isVehiclesDataLoading } = useGetAllCars()
  const navigate = useNavigate()
  const mappedData = useMemo(() => {
    if (!data) {
      return null
    }
    return data.map((item: any) => <ServiceCard item={item} key={item.id} />)
  }, [data])
  if (isLoading || isVehiclesDataLoading) {
    return (
      <>
        <Grid
          fontSize={'1.2rem'}
          fontWeight={'semibold'}
          w={'100%'}
          h={'4rem'}
          placeItems={'center'}
          bg={colors.gray[700]}
          color={colors.blue[100]}
          zIndex={100}
          position={'sticky'}
          top={'0px'}
        >
          Prehľad aktívnych servisov
        </Grid>
        <Grid h={'40rem'}>
          <Spinner />
        </Grid>
        <Menu type={'basic'} />
      </>
    )
  }

  return (
    <Flex w={'100%'} alignItems={'center'} flexFlow={'column'} bg={colors.blue[50]}>
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'4rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Prehľad aktívnych servisov
      </Grid>
      <Flex
        mt={'2rem'}
        flexFlow={'column nowrap'}
        overflow={'auto'}
        gap={'1rem'}
        mb={'8rem'}
        w={'90%'}
      >
        {mappedData}
        <Button
          size="large"
          variant="basic"
          w={{ sm: '100%', md: '30%' }}
          h={'unset'}
          bg={colors.blue[100]}
          onClick={() => navigate(RouterEnum.VEHICLE_SERVIS_CREATE)}
          isDisabled={vehiclesData?.length === 0 || totalBlock}
        >
          <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
            <Add fontSize={'2rem'} />
            <Settings fontSize={'2rem'} />
          </Flex>
          pridať servis
        </Button>
        {vehiclesData?.length === 0 && (
          <Box
            textAlign={'center'}
            color={colors.red[500]}
            fontWeight={'semibold'}
            fontSize={'0.8rem'}
            w={'100%'}
          >
            Vo Vašom pracovisku je nutné najprv založiť aspoň jedno vozidlo
          </Box>
        )}
      </Flex>
      <Menu back={() => navigate(-1)} type={'basic'} />
    </Flex>
  )
}

export default ServicesOverviewMobile
