import useGetWorkspaceById from '../../../model/api/useGetWorkspaceById'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Flex, Grid } from '@chakra-ui/react'
import { colors } from '../../../theme'
import Spinner from '../../../components/Spinner'
import Menu from '../../../components/Menu'
import { Box } from '../../../components/Box'
import Button from '../../../components/Button'
import Edit from '../../../icons/Edit'
import Lock from '../../../icons/Lock'
import TurnOffIcon from '../../../icons/TurnOffIcon'
import { useEffect, useState } from 'react'
import RouterEnum from '../../../model/enums/RouterEnum'
import * as Yup from 'yup'
import { useQueryClient } from 'react-query'
import ApiRouteEnum from '../../../model/enums/ApiRouteEnum'
import { useCustomToast } from '../../../components/Toast'
import useUpdateUser from '../../../model/api/useUpdateUser'
import useUpdatePassword from '../../../model/api/useUpdatePassword'
import { Field, Form, Formik } from 'formik'
import {
  AccountDataFormLabels,
  AccountPasswordFormLabels,
} from '../../../model/constants/FormLabels'
import { Input } from '../../../components/Input'
import getFieldStatus from '../../../model/helpers/StatusHelper'
import Save from '../../../icons/Save'
import { userDataFormSchema } from '../../../model/schemas/UserDataFormSchema'
import { passwordDataFormSchema } from '../../../model/schemas/PasswordDataFormSchema'
import useInitialValuesAccountData from '../../../model/initialValues/useInitialValuesAccountData'
import { ToastHelper } from '../../../model/helpers/ToastHelper'

const AccountMenuMobile = () => {
  const {data} = useOutletContext() as any
  const { data: workspaceData, isLoading } = useGetWorkspaceById(data.workspace.id)
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const navigate = useNavigate()
  const [accountManagementOption, setAccountManagementOption] = useState<string>('')
  const [content, setContent] = useState<any>()
  const { initialUserData, initialPasswordData } = useInitialValuesAccountData({ data })

  const successCallback = () => {
    query.invalidateQueries(ApiRouteEnum.USER)
    query.invalidateQueries(ApiRouteEnum.ME)
    showCustomToast({
      status: 'success',
      title: 'Údaje úspešne zmenené',
      label: <div>Vaše údaje boli úspešne zmenené</div>,
    })
  }
  const errorCallback = () => {
    showCustomToast({
      status: 'error',
      title: 'Údaje sa nepodarilo zmeniť',
      label: <div>Pri zmene Vašich údajov nastala chyba</div>,
    })
  }

  const mutateUser = useUpdateUser(successCallback, errorCallback)
  const mutatePassword = useUpdatePassword()
  const handleLogoutClick = () => {
    localStorage.setItem('token', '')
    navigate(RouterEnum.LOGIN)
  }

  const handleFormSubmit1 = (values: Yup.InferType<typeof userDataFormSchema>) => {
    mutateUser.mutate({ updateUserData: values, id: data.id })
  }

  const handleFormSubmit2 = (values: Yup.InferType<typeof passwordDataFormSchema>) => {
    mutatePassword.mutate(
      {
        updatePasswordData: { password: values.oldPassword, newPassword: values.newPassword },
        id: data.id,
      },
      {
        onSuccess: () => {
          query.invalidateQueries(ApiRouteEnum.USER)

          showCustomToast({
            status: 'success',
            title: 'Heslo bolo zmenené',
            label: <div>Vaše heslo bolo úspešne zmenené</div>,
          })
        },
        onError: () => {
          showCustomToast({
            status: 'error',
            title: 'Heslo sa nepodarilo zmeniť',
            label: <div>Pri zmene Vašeho hesla nastala chyba</div>,
          })
        },
      },
    )
  }

  useEffect(() => {
    if (accountManagementOption === '') {
      setContent(
        <Grid placeItems={'center'} m={'auto 0'} gap={'1rem'}>
          <Button h={'6rem'} variant={'blue'} size="medium" onClick={() => handleLogoutClick()}>
            <TurnOffIcon />
            <Box w={'70%'} whiteSpace={'pre-wrap'}>
              odhlásiť sa
            </Box>
          </Button>
          <Button
            h={'6rem'}
            variant={'basic'}
            size="medium"
            onClick={() => setAccountManagementOption('dataSettings')}
          >
            <Edit />
            <Box w={'70%'} whiteSpace={'pre-wrap'}>
              zmeniť údaje
            </Box>
          </Button>
          <Button
            h={'6rem'}
            variant={'basic'}
            size="medium"
            onClick={() => setAccountManagementOption('passwordSettings')}
          >
            <Lock />
            <Box w={'70%'} whiteSpace={'pre-wrap'}>
              zmena hesla
            </Box>
          </Button>
        </Grid>,
      )
    }
    if (accountManagementOption === 'dataSettings') {
      setContent(
        <Formik
          initialValues={initialUserData}
          onSubmit={handleFormSubmit1}
          validationSchema={userDataFormSchema}
        >
          {({ errors, touched, submitForm, setFieldTouched }) => {
            return (
              <>
                <Grid placeItems={'center'} m={'auto'} w={'100%'} h={'88%'}>
                  <Form style={{ width: '85%', height: '100%' }}>
                    <Flex
                      justifyContent={'space-between'}
                      flexFlow={'column nowrap'}
                      gap={'1rem'}
                      h={'85%'}
                    >
                      <Box></Box>
                      <Grid gap={'1rem'}>
                        <Field
                          bg={colors.blue[100]}
                          name="firstname"
                          label="krstné meno"
                          type="text"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'firstname')}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name="lastname"
                          label="priezvisko"
                          type="text"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'lastname')}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name="email"
                          label="e-mail"
                          type="email"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'email')}
                        />
                      </Grid>
                      <Button
                        onClick={() =>
                          ToastHelper({
                            submitForm,
                            errors,
                            setFieldTouched,
                            labels: AccountDataFormLabels,
                            showCustomToast,
                          })
                        }
                        size="full"
                        variant={'green'}
                        w={'100%'}
                      >
                        <Save />
                      </Button>
                    </Flex>
                  </Form>
                </Grid>
              </>
            )
          }}
        </Formik>,
      )
    }
    if (accountManagementOption === 'passwordSettings') {
      setContent(
        <Formik
          initialValues={initialPasswordData}
          onSubmit={handleFormSubmit2}
          validationSchema={passwordDataFormSchema}
        >
          {({ errors, touched, submitForm, setFieldTouched }) => {
            return (
              <>
                <Grid placeItems={'center'} w={'100%'} h={'88%'}>
                  <Form style={{ width: '85%', height: '100%' }}>
                    <Flex
                      justifyContent={'space-between'}
                      flexFlow={'column nowrap'}
                      gap={'1rem'}
                      h={'85%'}
                    >
                      <Box></Box>
                      <Grid gap={'1rem'}>
                        <Field
                          bg={colors.blue[100]}
                          name="oldPassword"
                          label="staré heslo:"
                          type="password"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'oldPassword')}
                        />
                        <br />
                        <Field
                          bg={colors.blue[100]}
                          name="newPassword"
                          label="nové heslo:"
                          type="password"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'newPassword')}
                        />
                        <Field
                          bg={colors.blue[100]}
                          name="confirmPassword"
                          label="zopakovať heslo:"
                          type="password"
                          component={Input}
                          status={getFieldStatus(errors, touched, 'confirmPassword')}
                        />
                      </Grid>

                      <Button
                        onClick={() =>
                          ToastHelper({
                            submitForm,
                            errors,
                            setFieldTouched,
                            labels: AccountPasswordFormLabels,
                            showCustomToast,
                          })
                        }
                        size="full"
                        variant={'green'}
                        w={'100%'}
                      >
                        <Save />
                      </Button>
                    </Flex>
                  </Form>
                </Grid>
              </>
            )
          }}
        </Formik>,
      )
    }
  }, [accountManagementOption])

  if (isLoading || accountManagementOption === undefined) {
    return (
      <>
        <Grid
          fontSize={'1.2rem'}
          fontWeight={'semibold'}
          w={'100%'}
          h={'4rem'}
          placeItems={'center'}
          bg={colors.gray[700]}
          color={colors.blue[100]}
          zIndex={100}
          position={'sticky'}
          top={'0px'}
        >
          Nastavenia účtu
        </Grid>
        <Grid h={'40rem'}>
          <Spinner />
        </Grid>
        <Menu type={'basic'} />
      </>
    )
  }

  return (
    <Flex w={'100%'} h={'100%'} bg={colors.blue[50]} flexFlow={'column'}>
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'6rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Nastavenia účtu
        <Box fontSize={'0.9rem'}>
          {data.firstname} {data.lastname}
        </Box>
        <Box fontSize={'0.9rem'} fontWeight={'normal'}>
          {workspaceData.name}
        </Box>
      </Grid>
      {content}
      <Menu
        back={
          accountManagementOption !== '' ? () => setAccountManagementOption('') : () => navigate(-1)
        }
        type={'basic'}
      />
    </Flex>
  )
}

export default AccountMenuMobile
