import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { Field } from 'formik'
import Button from '../components/Button'
import { Input } from '../components/Input'
import Switch from '../components/Switch'
import Textarea from '../components/Textarea'
import { Add } from '../icons/Add'
import Cancel from '../icons/Cancel'
import Close from '../icons/Close'
import ConfirmIcon from '../icons/ConfirmIcon'
import Delete from '../icons/Delete'
import EuroIcon from '../icons/EuroIcon'
import getFieldStatus from '../model/helpers/StatusHelper'
import { colors } from '../theme'
import { DisabledInput } from './DisabledInput'
import { useCustomToast } from './Toast'

const PriceModal = ({
  isPriceModalOpen,
  onPriceModalClose,
  values,
  errors,
  touched,
  setFieldValue,
  isReadOnly,
}: {
  isPriceModalOpen: boolean
  onPriceModalClose: () => void
  values: any
  errors?: any
  touched?: any
  setFieldValue?: any
  isReadOnly?: boolean
}) => {
  const { showCustomToast } = useCustomToast()
  if (isReadOnly) {
    return (
      <Modal size={'xl'} isOpen={isPriceModalOpen} onClose={onPriceModalClose} isCentered>
        <ModalOverlay />
        <ModalContent display={'flex'} justifyContent={'center'}>
          <ModalHeader display={'flex'} alignItems={'center'} p={0}>
            <EuroIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} /> Nastavenie
            ceny{' '}
            <Close
              fontSize={'1.6rem'}
              bg={colors.red[500]}
              ml={'auto'}
              cursor={'pointer'}
              onClick={() => onPriceModalClose()}
              p={4}
            />
          </ModalHeader>

          <ModalBody p={'1rem'} display={'flex'} flexFlow={'column'} gap={'1rem'}>
            {values.price !== null &&
            values.price.priceItems &&
            values.price.priceItems.length > 0 ? (
              <>
                {values.price?.priceItems &&
                  values.price?.priceItems.map((element: any, index: number) => {
                    return (
                      <Grid
                        textAlign={'center'}
                        fontWeight={'semibold'}
                        gap={'1rem'}
                        gridTemplateColumns={'repeat(2, 1fr)'}
                        key={'PriceItems' + index}
                      >
                        <DisabledInput
                          bg={colors.blue[100]}
                          name={`price.priceItems[${index}].displayName`}
                          label={'názov'}
                          defaultValue={element.displayName}
                        />
                        <DisabledInput
                          bg={colors.blue[100]}
                          name={`price.priceItems[${index}].price`}
                          label={'cena'}
                          type={'number'}
                          defaultValue={element.price}
                        />
                      </Grid>
                    )
                  })}

                <Grid
                  textAlign={'center'}
                  fontWeight={'semibold'}
                  gap={'1rem'}
                  gridTemplateColumns={'repeat(2, 1fr)'}
                >
                  <DisabledInput
                    bg={colors.blue[100]}
                    name="price.totalPrice"
                    label={'celková cena'}
                    defaultValue={values.price.totalPrice}
                  />
                </Grid>
              </>
            ) : (
              <Grid
                textAlign={'center'}
                fontWeight={'semibold'}
                gap={'2rem'}
                gridTemplateColumns={'repeat(2, 1fr)'}
              >
                <DisabledInput
                  bg={colors.blue[100]}
                  name="price.displayName"
                  defaultValue={values.price !== null ? values.price.displayName : ''}
                  label={'názov'}
                  component={Input}
                />
                <DisabledInput
                  bg={colors.blue[100]}
                  name="price.totalPrice"
                  defaultValue={values.price !== null ? values.price.totalPrice : ''}
                  label={'cena'}
                  type={'number'}
                  component={Input}
                />
              </Grid>
            )}
            <Textarea
              label={'poznámky'}
              helperText={'Obsah sa zobrazí majiteľovi vozidla pri zdieľaní'}
              placeholder={
                values.price !== null && values.price.description ? values.price.description : ''
              }
            />
          </ModalBody>

          <ModalFooter
            display={'flex'}
            justifyContent={'space-between'}
            gap={3}
            pt={'0'}
            m={'auto'}
            flexFlow={
              values.price !== null && values.price.priceItems.length < 0 ? 'row' : 'column'
            }
            w={'100%'}
          >
            <Flex
              alignItems={'center'}
              justifyContent={'space-between'}
              fontSize={'0.8rem'}
              w={'50%'}
              fontWeight={'semibold'}
            >
              rozpoložkovať cenu
              <Switch
                isChecked={
                  values.price !== null && values.price.priceItems.length > 0 ? true : false
                }
              />
            </Flex>

            <Flex gap={3} w={'50%'}>
              <Button variant={'blue'} onClick={onPriceModalClose}>
                <ConfirmIcon />
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }
  return (
    <Modal size={'xl'} isOpen={isPriceModalOpen} onClose={onPriceModalClose} isCentered>
      <ModalOverlay />
      <ModalContent display={'flex'} justifyContent={'center'}>
        <ModalHeader display={'flex'} alignItems={'center'} p={0}>
          <EuroIcon fontSize={'1.6rem'} color={colors.blue[500]} m={'0 6rem 0 2rem'} /> Nastavenie
          ceny{' '}
          <Close
            fontSize={'1.6rem'}
            bg={colors.red[500]}
            ml={'auto'}
            cursor={'pointer'}
            onClick={() => onPriceModalClose()}
            p={4}
          />
        </ModalHeader>

        <ModalBody p={'1rem'} display={'flex'} flexFlow={'column'} gap={'1rem'}>
          {values.priceItemsChecked ? (
            <>
              {values.price?.priceItems &&
                values.price?.priceItems.map((item: any, index: number) => {
                  return (
                    <Grid
                      textAlign={'center'}
                      fontWeight={'semibold'}
                      gap={'1rem'}
                      gridTemplateColumns={'repeat(2, 4fr) 1fr'}
                      key={'PriceItems' + index}
                    >
                      <Field
                        bg={colors.blue[100]}
                        name={`price.priceItems[${index}].displayName`}
                        label={'názov'}
                        component={Input}
                        readOnly={isReadOnly || false}
                        status={getFieldStatus(
                          errors,
                          touched,
                          `price.priceItems[${index}].displayName`,
                        )}
                      />
                      <Field
                        bg={colors.blue[100]}
                        name={`price.priceItems[${index}].price`}
                        label={'cena'}
                        type={'number'}
                        min={0}
                        component={Input}
                        status={getFieldStatus(errors, touched, `price.priceItems[${index}].price`)}
                        onChange={(e: any) => {
                          const newPriceItems = [...(values.price?.priceItems || [])]
                          newPriceItems[index] = {
                            ...newPriceItems[index],
                            price: Number(Number(e.target.value).toFixed(2)) || undefined,
                          }
                          setFieldValue('price.priceItems', newPriceItems)
                          const totalPrice = newPriceItems.reduce(
                            (acc, item) => Number(acc) + Number(item.price || 0),
                            0,
                          )
                          setFieldValue('price.totalPrice', Number(Number(totalPrice).toFixed(2)))
                        }}
                      />
                      <Button
                        variant={'red'}
                        onClick={() => {
                          const newPriceItems = [...(values.price?.priceItems || [])]
                          newPriceItems.splice(index, 1)
                          setFieldValue('price.priceItems', newPriceItems)
                          const totalPrice = newPriceItems.reduce(
                            (acc, item) => acc + Number(item.price || 0),
                            0,
                          )
                          setFieldValue('price.totalPrice', Number(Number(totalPrice).toFixed(2)))
                        }}
                      >
                        <Delete />
                      </Button>
                    </Grid>
                  )
                })}

              <Grid
                textAlign={'center'}
                fontWeight={'semibold'}
                gap={'2rem'}
                gridTemplateColumns={'repeat(2, 1fr)'}
              >
                <Field
                  bg={colors.blue[100]}
                  name="price.totalPrice"
                  label={'celková cena'}
                  type={'number'}
                  component={Input}
                  status={getFieldStatus(errors, touched, 'price.totalPrice')}
                />
                <Button
                  variant={'blue'}
                  onClick={() =>
                    setFieldValue('price.priceItems', [
                      ...(values.price?.priceItems || []),
                      { displayName: '', price: '' },
                    ])
                  }
                >
                  <Add fontSize={'1rem'} />
                  pridať položku
                </Button>
              </Grid>
            </>
          ) : (
            <Grid
              textAlign={'center'}
              fontWeight={'semibold'}
              gap={'2rem'}
              gridTemplateColumns={'repeat(2, 1fr)'}
            >
              <Field
                bg={colors.blue[100]}
                name="price.displayName"
                label={'názov'}
                component={Input}
                status={getFieldStatus(errors, touched, 'price.displayName', true, values)}
              />
              <Field
                bg={colors.blue[100]}
                name="price.totalPrice"
                label={'cena'}
                type={'number'}
                component={Input}
                status={getFieldStatus(errors, touched, 'price.totalPrice', true, values)}
                onChange={(e: any) => {
                  setFieldValue(
                    'price.totalPrice',
                    Number(Number(e.target.value).toFixed(2)) || undefined,
                  )
                }}
              />
            </Grid>
          )}
          <Field
            name="price.description"
            label={'poznámky'}
            helperText={'Obsah sa zobrazí majiteľovi vozidla pri zdieľaní'}
            component={Textarea}
          />
        </ModalBody>
        <ModalFooter
          display={'flex'}
          justifyContent={'space-between'}
          gap={3}
          pt={'0'}
          m={'auto'}
          flexFlow={values.priceItemsChecked ? 'row' : 'column'}
          w={'100%'}
        >
          <Flex
            alignItems={'center'}
            justifyContent={'space-between'}
            fontSize={'0.8rem'}
            w={'50%'}
            fontWeight={'semibold'}
          >
            rozpoložkovať cenu{' '}
            <Field
              name="priceItemsChecked"
              isChecked={values.priceItemsChecked}
              component={Switch}
              onChange={(e: any) => {
                setFieldValue('priceItemsChecked', e.target.checked)
                if (e.target.checked === true) {
                  setFieldValue('price.displayName', null)
                }
                if (e.target.checked === false) {
                  setFieldValue('price.displayName', '')
                }
              }}
            />
          </Flex>

          <Flex gap={3} w={'50%'}>
            <Button
              variant={'green'}
              onClick={() => {
                if (values.priceItemsChecked === true && values.price.priceItems.length === 0) {
                  showCustomToast({
                    status: 'error',
                    title: 'Cena neobsahuje položky',
                    label: (
                      <div>
                        Rozpoložkovaná cena musí obsahovať aspoň jednu položku. Ak nechcete, aby
                        bola cena rozpoložkovaná, odštránte tento výber vo formulári.
                      </div>
                    ),
                  })
                } else if (
                  values.priceItemsChecked === false &&
                  values.price.totalPrice &&
                  !values.price.displayName
                ) {
                  showCustomToast({
                    status: 'error',
                    title: 'Cena neobsahuje názov',
                    label: (
                      <div>
                        Položka <b>Názov</b> vo formulári ceny nesmie byť prázdna.
                      </div>
                    ),
                  })
                } else if (
                  values.priceItemsChecked === true &&
                  values.price.priceItems.length > 0 &&
                  values.price.priceItems.some(
                    (item: any) => item.price === undefined || item.displayName === '',
                  )
                ) {
                  showCustomToast({
                    status: 'error',
                    title: 'Položky ceny sú neúplné',
                    label: <div>Názov aj cena každej položky musia byť vyplnené.</div>,
                  })
                } else if (!values.price?.totalPrice) {
                  setFieldValue('price', {
                    totalPrice: 0,
                    priceItems: [],
                    displayName: null,
                    description: null,
                  })
                  onPriceModalClose()
                } else {
                  onPriceModalClose()
                }
              }}
            >
              <ConfirmIcon />
            </Button>
            <Button
              variant={'blue'}
              onClick={() => {
                setFieldValue('price', {
                  totalPrice: 0,
                  priceItems: [],
                  displayName: null,
                  description: null,
                })
                onPriceModalClose()
              }}
            >
              <Cancel />
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PriceModal
