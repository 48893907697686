import { Outlet } from 'react-router-dom'
import MainMenu from './components/MainMenu'
import { Flex } from '@chakra-ui/layout'
import Header from './components/Header'
import { colors } from './theme'
import useGetCurrentUser from './model/api/useGetCurrentUser'
import useIsMobile from './model/helpers/useIsMobile'
import useGetWorkspaceQuotas from './model/api/useGetWorkspaceQuotas'
import { useEffect, useState } from 'react'
import { useCustomToast } from './components/Toast'

function App() {
  const isMobile = useIsMobile()
  const { data } = useGetCurrentUser()
  const { showCustomToast } = useCustomToast()
  const { data: workspaceDataQuotas } = useGetWorkspaceQuotas(data && data.workspace.id)
  const [totalBlock, setTotalBlock] = useState(false)

  useEffect(() => {
    setTotalBlock(false)
    if (workspaceDataQuotas) {
      for (const [key] of Object.entries(workspaceDataQuotas)) {
        if (
          workspaceDataQuotas[key] &&
          workspaceDataQuotas[key].current &&
          workspaceDataQuotas[key].current > workspaceDataQuotas[key].maximum
        ) {
          setTotalBlock(true)
        }
      }
    }
  }, [workspaceDataQuotas])

  useEffect(() => {
    if (totalBlock) {
      showCustomToast({
        status: 'warning',
        title: 'Prekročený limit',
        label: (
          <div>
            Nedávno boli prekročené maximálne limity vo Vašej organizácii. Funkcionality Vašej
            aplikácie budú dočasne pozastavené, kým nebudú limity splnené alebo kým ich nenavýšite
            odberom vyššieho balíčka.
          </div>
        ),
      })
    }
  }, [totalBlock])

  if (!data) {
    return null
  }
  if (isMobile) {
    return (
      <Flex flexFlow={'column'} w={'100%'} h={'100%'} bg={colors.blue[50]}>
        <Outlet context={{ data: data, totalBlock: totalBlock }} />
      </Flex>
    )
  }
  return (
    <Flex w={'100%'} h={'100%'} bg={colors.blue[50]}>
      <MainMenu />
      <Flex flexFlow={'column nowrap'} w={'80%'}>
        <Header data={data} />
        <Outlet context={{ data: data, totalBlock: totalBlock }} />
      </Flex>
    </Flex>
  )
}

export default App
