import { Box } from './Box'
import { colors } from '../theme'
import { Badge, Flex, useDisclosure } from '@chakra-ui/react'
import IconButton from './IconButton'
import Edit from '../icons/Edit'
import Delete from '../icons/Delete'
import { useDeleteUser } from '../model/api/useDeleteUser'
import PeopleGroupIcon from '../icons/PeopleGroupIcon'
import DeleteModal from './DeleteModal'
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import InviteEmployeeForm from '../pages/workspaceManagement/InviteEmployeeForm'
import ApiRouteEnum from '../model/enums/ApiRouteEnum'
import { useQueryClient } from 'react-query'
import { useCustomToast } from './Toast'
import { useOutletContext } from 'react-router'

const EmployeeCard = ({ item }: { item: any }) => {
  const mutateDelete = useDeleteUser()
  const { data: outletData } = useOutletContext() as any
  const query = useQueryClient()
  const { showCustomToast } = useCustomToast()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isEditEmployeeModalOpen,
    onOpen: onEditEmployeeModalOpen,
    onClose: onEditEmployeeModalClose,
  } = useDisclosure()
  return (
    <Box w={{ sm: '100%', md: '30%' }}>
      <Box
        w={'100%'}
        pb={5}
        bg={item.activatedAt === null ? colors.gray[50] : colors.blue[100]}
        position={'relative'}
      >
        <Flex alignItems={'center'}>
          <Box w={'3rem'} h={'3rem'} bg={colors.gray[700]} display={'grid'} placeItems={'center'}>
            <PeopleGroupIcon color={colors.blue[500]} />
          </Box>
          <Box fontWeight={'semibold'} w={'80%'} textAlign={'center'}>
            {item.firstname + ' ' + item.lastname}
          </Box>
        </Flex>
        <br />
        <Box pl={'1rem'}>
          <Box fontWeight={'semibold'} mb={'5px'}>
            Email:
          </Box>
          <Box>{item.email}</Box>
        </Box>
        <br />
        <Flex w={'100%'} justifyContent={'space-evenly'}>
          <IconButton
            variant={'blue'}
            icon={<Edit />}
            size={'half'}
            onClick={onEditEmployeeModalOpen}
          />
          {outletData.roles.includes('ROLE_WORKSPACE_OWNER') && (
            <IconButton
              variant={'red'}
              icon={<Delete />}
              size={'half'}
              onClick={onDeleteModalOpen}
            />
          )}
        </Flex>
        {item.activatedAt === null && (
          <Badge fontSize={'0.8rem'} position={'absolute'} top={0} right={0} colorScheme="purple">
            Neaktivní
          </Badge>
        )}
      </Box>

      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        onDeleteModalClose={onDeleteModalClose}
        titleValue={item.firstname + ' ' + item.lastname}
        onClickAction={() => {
          mutateDelete.mutate(item.id, {
            onSuccess: () => {
              query.invalidateQueries(ApiRouteEnum.USER)
              onDeleteModalClose()
              showCustomToast({
                status: 'success',
                title: 'Položka úspešne odstránená',
                label: (
                  <div>
                    Zákazník <b>{item.firstname + ' ' + item.lastname}</b> bol úspešne odstránený z
                    evidencie
                  </div>
                ),
              })
              query.invalidateQueries('workspaceByQuotas')
            },
            onError: () => {
              onDeleteModalClose()
              showCustomToast({
                status: 'error',
                title: 'Položku sa nepodarilo odstrániť',
                label: (
                  <div>
                    Zákazníka <b>{item.firstname + ' ' + item.lastname}</b> sa nepodarilo odstrániť
                  </div>
                ),
              })
            },
          })
        }}
      />

      <Modal
        size={'xs'}
        isOpen={isEditEmployeeModalOpen}
        onClose={onEditEmployeeModalClose}
        isCentered={true}
        variant={'confirm'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={colors.gray[700]} color={colors.blue[100]}>
            Upraviť zamestnanca
          </ModalHeader>
          <ModalBody>
            <InviteEmployeeForm employee={item} closeModal={onEditEmployeeModalClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default EmployeeCard
