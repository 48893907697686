import { useMutation } from 'react-query'
import api from './api'
import ApiRouteEnum from '../enums/ApiRouteEnum'

const useCreatePayment = () => {
  return useMutation(
    ({
      paymentData,
    }: {
      paymentData: {
        amount: number
        workspaceId: string
        tier: {
          maxUsers: number
          maxVehicles: number
          diskSize: number
          maximumEmailsPerMonth: number
          maxApiCallsPerMonth: number
        }
      }
    }) => api.post(`${ApiRouteEnum.PAYMENT}`, paymentData),
    {
      mutationKey: [ApiRouteEnum.PAYMENT],
    },
  )
}

export default useCreatePayment
