import { Box } from './Box'
import { colors } from '../theme'
import CarIcon from '../icons/CarIcon'
import { Flex, useDisclosure } from '@chakra-ui/react'
import Button from './Button'
import IconButton from './IconButton'
import Edit from '../icons/Edit'
import Settings from '../icons/Settings'
import Delete from '../icons/Delete'
import { useDeleteCar } from '../model/api/useDeleteCar'
import { useNavigate, useOutletContext } from 'react-router-dom'
import RouterEnum from '../model/enums/RouterEnum'
import DeleteModal from './DeleteModal'
import CreateServiceModal from './CreateServiceModal'
import Spinner from './Spinner'
import { useQueryClient } from 'react-query'
import ApiRouteEnum from '../model/enums/ApiRouteEnum'
import { useCustomToast } from './Toast'

const VehicleCard = ({ item }: { item: any }) => {
  const mutateDelete = useDeleteCar()
  const { totalBlock } = useOutletContext() as any
  const { showCustomToast } = useCustomToast()
  const query = useQueryClient()
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure()
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose,
  } = useDisclosure()
  const navigate = useNavigate()

  return (
    <Box w={{ sm: '90%', md: '30%' }} cursor={'pointer'} mb={{ sm: '1rem', md: '0' }}>
      {!item ? (
        <Spinner />
      ) : (
        <>
          <Box w={'100%'} pb={5} bg={colors.blue[100]}>
            <Flex alignItems={'center'} mb={'0.8rem'}>
              <Box
                w={'3rem'}
                h={'3rem'}
                bg={colors.gray[700]}
                display={'grid'}
                placeItems={'center'}
              >
                <CarIcon color={colors.blue[500]} />
              </Box>
              <Box fontWeight={'semibold'} w={'80%'} textAlign={'center'}>
                {item.displayName ? item.displayName : item.brand + ' ' + item.model}
              </Box>
            </Flex>
            <Flex
              pl={'1rem'}
              gap={'0.8rem'}
              flexFlow={'column'}
              onClick={
                !totalBlock
                  ? () => navigate(RouterEnum.VEHICLE_DETAIL, { state: { car: item } })
                  : () => {}
              }
            >
              <Box>
                <Box fontWeight={'semibold'} mb={'5px'}>
                  VIN:
                </Box>
                <Box>{item.vinCode}</Box>
              </Box>
              <Box>
                <Box fontWeight={'semibold'} mb={'5px'}>
                  SPZ:
                </Box>
                <Box>{item.licensePlate ? item.licensePlate : '-'}</Box>
              </Box>
              <Box>
                <Box fontWeight={'semibold'} mb={'5px'}>
                  Majiteľ:
                </Box>
                <Box>
                  {item.vehicleOwner.firstname} {item.vehicleOwner.lastname}
                </Box>
              </Box>
              <Box>
                <Box fontWeight={'semibold'} mb={'5px'}>
                  Rok výroby:
                </Box>
                <Box>{item.manufacturedAt}</Box>
              </Box>
            </Flex>
            <Button
              variant={'transparent'}
              onClick={() =>
                navigate('/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW, { state: { car: item.id } })
              }
              mb={'0.8rem'}
            >
              servisná história
            </Button>
            <Flex w={'100%'} justifyContent={'space-between'} gap={'0.5rem'} p={'0 1rem'}>
              <IconButton
                isDisabled={totalBlock}
                variant={'blue'}
                icon={<Edit />}
                size={'third'}
                onClick={() =>
                  navigate(RouterEnum.VEHICLE_FORM_EDIT, { state: { carId: item.id } })
                }
              />
              <IconButton
                isDisabled={totalBlock}
                variant={'dark'}
                icon={<Settings />}
                size={'third'}
                onClick={onConfirmModalOpen}
              />
              <IconButton
                variant={'red'}
                icon={<Delete />}
                size={'third'}
                onClick={onDeleteModalOpen}
              />
            </Flex>
          </Box>

          <DeleteModal
            titleValue={item.displayName}
            isDeleteModalOpen={isDeleteModalOpen}
            onDeleteModalClose={onDeleteModalClose}
            onClickAction={() => {
              mutateDelete.mutate(item.id, {
                onSuccess: () => {
                  query.invalidateQueries(ApiRouteEnum.VEHICLE)
                  onDeleteModalClose()
                  showCustomToast({
                    status: 'success',
                    title: 'Položka úspešne odstránená',
                    label: (
                      <div>
                        Vozidlo <b>{item.displayName}</b> bolo úspešne odstránené z evidencie
                      </div>
                    ),
                  })
                  query.invalidateQueries('workspaceByQuotas')
                },
                onError: () => {
                  onDeleteModalClose()
                  showCustomToast({
                    status: 'error',
                    title: 'Položku sa nepodarilo odstrániť',
                    label: (
                      <div>
                        Vozidlo <b>{item.displayName}</b> sa nepodarilo odstrániť
                      </div>
                    ),
                  })
                },
              })
            }}
          />

          <CreateServiceModal
            isConfirmModalOpen={isConfirmModalOpen}
            onConfirmModalClose={onConfirmModalClose}
            onClickAction={() =>
              navigate(
                '/' + RouterEnum.VEHICLE_SERVIS_OVERVIEW + '/' + RouterEnum.VEHICLE_SERVIS_CREATE,
                { state: { car: { label: item.displayName, value: item.id }, mode: 'create' } },
              )
            }
          />
        </>
      )}
    </Box>
  )
}

export default VehicleCard
