import { Flex, Grid, useDisclosure } from '@chakra-ui/react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import useGetWorkspaceQuotas from '../../../model/api/useGetWorkspaceQuotas'
import React, { useEffect, useMemo, useState } from 'react'
import useGetAllCars from '../../../model/api/useGetAllCars'
import VehicleCard from '../../../components/VehicleCard'
import Spinner from '../../../components/Spinner'
import { Box } from '../../../components/Box'
import FilterTag from '../../../components/FilterTag'
import Button from '../../../components/Button'
import { colors } from '../../../theme'
import RouterEnum from '../../../model/enums/RouterEnum'
import { Add } from '../../../icons/Add'
import CarIcon from '../../../icons/CarIcon'
import FilterModal from '../../../components/FilterModal'
import Menu from '../../../components/Menu'
import useGetAllVehicleOwners from '../../../model/api/useGetAllVehicleOwners'

const VehiclesOverviewMobile = () => {
  const {data: outletData, totalBlock} = useOutletContext() as any
  const { data: workspaceDataQuotas } = useGetWorkspaceQuotas(outletData.workspace.id)
  const { data: vehicleOwnersData, isLoading: isVehicleOwnersLoading } = useGetAllVehicleOwners()
  const [filters, setFilters] = useState<{
    [key: string]: any
  }>({})
  const [data, setData] = useState<any>()
  const { data: vehiclesData, getAllCars, isLoading } = useGetAllCars()
  const navigate = useNavigate()

  useEffect(() => {
    setData(vehiclesData)
  }, [vehiclesData])

  useEffect(() => {
    getAllCars({
      ...filters,
      ...(filters.ownerId && { ownerId: filters.ownerId.value }),
    }).then((res) => {
      setData(res)
    })
  }, [filters])

  const {
    isOpen: isAddFilterOpen,
    onOpen: onAddFilterOpen,
    onClose: onAddFilterClose,
  } = useDisclosure()

  const handleFilterRemove = (key: string) => {
    const newFilters = { ...filters }
    delete newFilters[key]
    setFilters(newFilters)
  }

  const handleResetFilters = () => {
    setFilters({})
  }

  const filterLabels = {
    ownerId: 'Majiteľ',
    model: 'Model',
    licensePlate: 'ŠPZ',
    ownerEmail: 'Email majiteľa',
    brand: 'Značka',
    vinCode: 'VIN',
  }

  const mappedData = useMemo(() => {
    if (!data) {
      return null
    }
    return (
      Array.isArray(data) &&
      data.map((item: any) => {
        return <VehicleCard item={item} key={item.id} />
      })
    )
  }, [data])

  if (isLoading || !workspaceDataQuotas || isVehicleOwnersLoading) {
    return (
      <>
        <Grid
          fontSize={'1.2rem'}
          fontWeight={'semibold'}
          w={'100%'}
          h={'4rem'}
          placeItems={'center'}
          bg={colors.gray[700]}
          color={colors.blue[100]}
          zIndex={100}
          position={'sticky'}
          top={'0px'}
        >
          Prehľad vozidiel v evidencii
        </Grid>
        <Grid h={'40rem'}>
          <Spinner />
        </Grid>
        <Menu type={'full'} />
      </>
    )
  }

  return (
    <>
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'4rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Prehľad vozidiel v evidencii
      </Grid>
      <Flex
        w={'100%'}
        alignItems={'center'}
        flexFlow={'column'}
        pb={'8rem'}
        h={'100%'}
        overflow={'auto'}
        bg={colors.blue[50]}
      >
        <Flex
          fontSize={'1rem'}
          fontWeight={'semibold'}
          alignItems={'center'}
          m={'1rem'}
          flexWrap={'wrap'}
          gap={'0.5rem'}
        >
          <Box>Aktívne filtre:</Box>
          <Flex flexWrap={'wrap'} gap={2}>
            {Object.keys(filters).map((key) => {
              if (typeof filters[key] === 'object') {
                return (
                  <FilterTag
                    title={`${filterLabels[key as keyof typeof filterLabels]}: ${
                      filters[key].label
                    }`}
                    onClick={() => handleFilterRemove(key)}
                  />
                )
              } else {
                return (
                  <FilterTag
                    title={`${filterLabels[key as keyof typeof filterLabels]}: ${filters[key]}`}
                    onClick={() => handleFilterRemove(key)}
                  />
                )
              }
            })}
            <FilterTag type={'add'} title={'pridať filter'} onClick={onAddFilterOpen} />
          </Flex>
        </Flex>
        {mappedData}
        <Button
          size="large"
          variant="basic"
          w={'90%'}
          h={'unset'}
          bg={colors.blue[100]}
          onClick={() => navigate(RouterEnum.VEHICLE_FORM_CREATE, { state: { mode: 'create' } })}
          isDisabled={
            workspaceDataQuotas.vehicle.current >= workspaceDataQuotas.vehicle.maximum ||
            vehicleOwnersData?.length === 0 || totalBlock
          }
        >
          <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
            <Add fontSize={'2rem'} />
            <CarIcon fontSize={'2rem'} />
          </Flex>
          pridať vozidlo
        </Button>
        {vehicleOwnersData?.length === 0 && (
          <Box
            textAlign={'center'}
            color={colors.red[500]}
            fontWeight={'semibold'}
            fontSize={'0.8rem'}
            w={'80%'}
          >
            Vo Vašom pracovisku je nutné najprv založiť aspoň jedného zákazníka.
          </Box>
        )}
        {workspaceDataQuotas.vehicle.current >= workspaceDataQuotas.vehicle.maximum && (
          <Box
            textAlign={'center'}
            color={colors.red[500]}
            fontWeight={'semibold'}
            fontSize={'0.8rem'}
            w={'80%'}
          >
            Vo Vašom pracovisku bol dosiahnutý maximálny možný počet vozidiel. Ak chcete pridať
            ďalšie vozidlo, odstráňte nejaké z existujúcich alebo navýšte limit v nastaveniach
            pracoviska.
          </Box>
        )}
        <FilterModal
          isAddFilterOpen={isAddFilterOpen}
          onAddFilterClose={onAddFilterClose}
          setFilters={setFilters}
          filters={filters}
          handleResetFilters={handleResetFilters}
        />
      </Flex>
      <Menu search={() => onAddFilterOpen()} back={() => navigate(-1)} type={'full'} />
    </>
  )
}

export default VehiclesOverviewMobile
