import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import Spinner from '../../../components/Spinner'
import useGetAllUsers from '../../../model/api/useGetAllUsers'
import { Box } from '../../../components/Box'
import Button from '../../../components/Button'
import { colors } from '../../../theme'
import { Add } from '../../../icons/Add'
import PeopleGroupIcon from '../../../icons/PeopleGroupIcon'
import EmployeeCard from '../../../components/EmployeeCard'
import InviteEmployeeForm from '../InviteEmployeeForm'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Menu from '../../../components/Menu'
import useGetWorkspaceQuotas from '../../../model/api/useGetWorkspaceQuotas'

const EmployeesOverviewMobile = () => {
  const { data, isLoading } = useGetAllUsers()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const { data: outletData, totalBlock } = useOutletContext() as any
  const { data: workspaceDataQuotas, isLoading: workspaceLoading } = useGetWorkspaceQuotas(
    outletData.workspace.id,
  )
  if (isLoading || workspaceLoading || !workspaceDataQuotas) {
    return <Spinner />
  }

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      alignItems={'center'}
      flexFlow={'column'}
      overflow={'auto'}
      bg={colors.blue[50]}
    >
      <Grid
        fontSize={'1.2rem'}
        fontWeight={'semibold'}
        w={'100%'}
        h={'4rem'}
        placeItems={'center'}
        bg={colors.gray[700]}
        color={colors.blue[100]}
        zIndex={100}
        position={'sticky'}
        top={'0px'}
      >
        Nastavenia workspace
      </Grid>
      <Flex
        p={'2rem 0 6rem 0'}
        flexFlow={'column'}
        gap={'1rem'}
        overflow={'auto'}
        h={'90%'}
        w={'90%'}
      >
        {data &&
          data.map((item: any) => {
            return <EmployeeCard item={item} key={item.id} />
          })}
        {outletData.roles.includes('ROLE_WORKSPACE_OWNER') && (
          <>
            <Button
              size="large"
              variant="basic"
              w={'100%'}
              bg={colors.blue[100]}
              onClick={onOpen}
              isDisabled={
                workspaceDataQuotas.users.current >= workspaceDataQuotas.users.maximum || totalBlock
              }
            >
              <Flex w={'8rem'} alignItems={'center'} justifyContent={'space-around'} mb={'1rem'}>
                <Add fontSize={'2rem'} />
                <PeopleGroupIcon fontSize={'2rem'} />
              </Flex>
              pridať zamestnanca
            </Button>
            {workspaceDataQuotas.users.current >= workspaceDataQuotas.users.maximum && (
              <Box
                textAlign={'center'}
                color={colors.red[500]}
                fontWeight={'semibold'}
                fontSize={'0.8rem'}
                w={'100%'}
              >
                Vo Vašom pracovisku bol dosiahnutý maximálny možný počet užívateľov. Ak chcete
                pridať ďalšieho užívateľa, odstráňte nejakého z existujúcich alebo navýšte limit v
                nastaveniach pracoviska.
              </Box>
            )}
          </>
        )}
      </Flex>
      <Menu back={() => navigate(-1)} type={'basic'} />
      <Modal size={'xs'} isOpen={isOpen} onClose={onClose} isCentered={true} variant={'confirm'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={colors.gray[700]} color={colors.blue[100]}>
            Pozvať zamestnanca?
          </ModalHeader>
          <ModalBody>
            <InviteEmployeeForm closeModal={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default EmployeesOverviewMobile
