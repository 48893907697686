enum RouterEnum {
  ADD_MENU = '/addMenu',
  CUSTOMER_MANAGEMENT = 'customerManagement',
  EMPLOYEES_MANAGEMENT = 'employeesManagement',
  FORGOTTEN_PASSWORD = '/forgottenPassword',
  FORGOTTEN_PASSWORD_CHANGE = '/forgottenPasswordChange',
  ACCOUNT_MANAGEMENT = 'accountManagement',
  HOMEPAGE = '/',
  LOGIN = '/login',
  MENU = '/menu', 
  REGISTER = '/register',
  REGISTER_AFTER_INVITE = '/user-activation',
  SHARE_MENU = '/shareMenu',
  VEHICLE_FORM_CREATE = 'vehicleFormCreate',
  VEHICLE_FORM_EDIT = 'vehicleFormEdit',
  VEHICLE_DETAIL = 'vehicleDetail',
  VEHICLE_MANAGEMENT = 'vehicleManagement',
  VEHICLE_SERVIS_CREATE = 'vehicleServisCreate',
  VEHICLE_SERVIS_DETAIL = 'vehicleServisDetail',
  VEHICLE_SERVIS_EDIT = 'vehicleServisEdit',
  VEHICLE_SERVIS_OVERVIEW = 'serviceManagement',
  WORKSPACE_LIMITS = '/workspaceLimits',
  WORKSPACE_LIMITS_SETTINGS = 'workspaceLimitsSettings',
  WORKSPACE_MANAGEMENT = 'workspaceManagement',
}
export default RouterEnum
